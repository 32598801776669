import { isRouteErrorResponse, useRouteError } from "react-router-dom"

export const ErrorBoundary = () => {
  const error = useRouteError()
  if (isRouteErrorResponse(error)) {
    return (
      <div className="max-w-7xl mx-auto">
        <div className="border-2 border-red-700 p-4 py-2">
          <h1 className="text-red-700">{error.status}</h1>
        </div>
      </div>
    )
  }

  throw error
}
