import { useState } from "react"
import clsx from "clsx"
import {
  ContactAvailabilityEnum,
  ContactStateEnum,
  Contact_ContactListFragmentDoc,
} from "~/__generated__/graphql"
import { Button } from "../ui/Button"
import { ContactForm } from "./ContactForm"
import { ArchiveContactForm } from "./ArchiveContactForm"
import { EmailActivatedContactForm } from "./EmailActivatedContactForm"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import toast from "react-hot-toast"
import { ACTIVE_CONTACTS_QUERY } from "~/screens/ActiveContactsScreen"
import { PENDING_CONTACTS_QUERY } from "~/screens/PendingContactsScreen"
import { ARCHIVED_CONTACTS_QUERY } from "~/screens/ArchivedContactsScreen"
import { CONTACT_LAYOUT_COUNTS_QUERY } from "~/layouts/ContactsLayout"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import Tippy from "@tippyjs/react"
import {
  ActivateButton,
  ArchiveButton,
  DeleteButton,
} from "./contactCardButtons"
import { format, parseISO, formatDistanceToNow } from "date-fns"

interface ContactCardProps {
  contact: FragmentType<typeof Contact_ContactListFragmentDoc>
  sortableKnob?: React.ReactNode
  className?: string
  includeRecurrence?: boolean
  emailPromptWhenActivated?: boolean
}

export const ContactCard = ({
  contact: _contact,
  sortableKnob,
  className,
  includeRecurrence = false,
  emailPromptWhenActivated = false,
}: ContactCardProps) => {
  const contact = getFragmentData(Contact_ContactListFragmentDoc, _contact)
  const currentUser = useCurrentUser()
  const [markAsReadMutation] = useSafeMutation(MARK_CONTACT_AS_READ)
  const [
    sendAvailabilityTextMutation,
    { loading: sendAvailabilityTextLoading },
  ] = useSafeMutation(SEND_AVAILABILITY_TEXT)
  const [
    sendFormRequestEmailMutation,
    { loading: sendFormRequestEmailLoading },
  ] = useSafeMutation(SEND_FORM_REQUEST_EMAIL)
  const [expanded, setExpanded] = useState(false)
  const [showActivationEmail, setShowActivationEmail] = useState(false)
  const [showRecurranceOptions, setShowRecurranceOptions] = useState(false)
  const refetchQueries = [
    ACTIVE_CONTACTS_QUERY,
    ARCHIVED_CONTACTS_QUERY,
    PENDING_CONTACTS_QUERY,
    CONTACT_LAYOUT_COUNTS_QUERY,
  ]

  const sendFormRequestEmail = async (contactId: string) => {
    const { errors } = await sendFormRequestEmailMutation({
      variables: {
        input: {
          contactId,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      toast.success(`Email sent to ${contact.name}`)
    }
  }

  const sendAvailabilityText = async (contactId: string) => {
    const { errors } = await sendAvailabilityTextMutation({
      variables: {
        input: {
          contactId,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      toast.success(
        `Message sent to ${contact.currentPhone}: ${currentUser.textCopy}`
      )
    }
  }

  const [availabilityColor, availabilityText] = contactAvailabilityToColor(
    contact.currentAvailability
  )

  const onExpand = async () => {
    if (expanded) return

    if (contact.unreadUpdates && contact.state === ContactStateEnum.Active) {
      await markAsReadMutation({
        variables: {
          input: {
            id: contact.id,
          },
        },
      })
    }

    setExpanded(true)
  }

  const unreadUpdates =
    contact.unreadUpdates && contact.state === ContactStateEnum.Active
  const notes =
    contact.state === ContactStateEnum.Pending
      ? contact.contactCallNotes
      : !!contact.notes
      ? contact.notes
      : contact.contactCallNotes

  return (
    <div
      className={clsx(
        expanded &&
          "flex fixed top-0 left-0 z-30 w-full h-full bg-white dark:bg-black overflow-hidden p-4"
      )}
      onClick={(event) => {
        if (expanded && event.target === event.currentTarget) {
          setExpanded(false)
          setShowRecurranceOptions(false)
        }
      }}
    >
      <div
        className={clsx(
          "border-2 p-4 hover:border-black dark:hover:border-white bg-white dark:bg-black",
          expanded &&
            "border-black hover:border-black dark:border-white dark:hover:border-white !m-auto h-full md:max-h-min overflow-y-auto w-full max-w-7xl pt-0",
          !expanded && "dark:border-gray-600",
          className
        )}
        onClick={onExpand}
      >
        <div
          className={clsx(
            "flex flex-wrap gap-4 items-start",
            expanded &&
              "sticky top-0 bg-white dark:bg-black py-4 border-b dark:border-gray-600 items-center"
          )}
        >
          {/* Close button, reordering handle, and tooltip for active contacts */}
          {contact.state === ContactStateEnum.Active && (
            <Tippy
              content={availabilityText}
              placement="top-start"
              disabled={expanded}
            >
              <div className="text-center relative">
                <div
                  className={clsx("rounded-full w-8 h-8", availabilityColor)}
                ></div>
                <div className="absolute w-8 h-8 flex items-center justify-center top-0 left-0">
                  {!expanded ? (
                    sortableKnob
                  ) : (
                    <button
                      type="button"
                      className="text-xl text-black [text-shadow:_0_1px_0_#FFF]"
                      onClick={() => setExpanded(false)}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </div>
            </Tippy>
          )}

          {/* Close button for states other than active */}
          {contact.state !== ContactStateEnum.Active && expanded && (
            <div className="rounded-full bg-gray-200 flex items-center justify-center">
              <button
                type="button"
                className="text-xl text-black [text-shadow:_0_1px_0_#FFF] w-8 h-8"
                onClick={() => setExpanded(false)}
              >
                &times;
              </button>
            </div>
          )}

          <div className="flex-1">
            <h2>
              {contact.name}{" "}
              {unreadUpdates && (
                <small className="text-sm">
                  <button
                    className="hover:text-pink-600 underline whitespace-nowrap"
                    onClick={() => setExpanded((prev) => !prev)}
                  >
                    ⚡️updated⚡
                  </button>
                </small>
              )}
            </h2>
            {contact.state !== ContactStateEnum.Archived && !expanded && (
              <div>
                {contact.currentEmail &&
                  contact.state === ContactStateEnum.Pending && (
                    <div>{contact.currentEmail}</div>
                  )}
                {contact.currentPhone && (
                  <div>
                    {contact.state === ContactStateEnum.Pending ? (
                      contact.currentPhone
                    ) : (
                      <a
                        href={`tel:${contact.currentPhone}`}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      >
                        {contact.currentPhone}
                      </a>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="w-full flex gap-2 md:w-[unset]">
            {contact.state === ContactStateEnum.Active &&
              !emailPromptWhenActivated &&
              !showRecurranceOptions && (
                <>
                  <Tippy
                    content="Send an email to request their contact information and availability"
                    placement="top-end"
                    disabled={expanded}
                  >
                    <div>
                      <Button
                        size="sm"
                        label="Email"
                        disabled={
                          !contact.currentEmail ||
                          sendFormRequestEmailLoading ||
                          !contact.canSendFormRequestEmail
                        }
                        onClick={(e) => {
                          e.stopPropagation()
                          sendFormRequestEmail(contact.id)
                        }}
                      />
                    </div>
                  </Tippy>{" "}
                  <Tippy
                    content="Send a text message to ask if they are available now to chat"
                    placement="top-end"
                    disabled={expanded}
                  >
                    <div>
                      <Button
                        size="sm"
                        label="Text"
                        disabled={
                          !contact.currentPhone ||
                          sendAvailabilityTextLoading ||
                          !contact.canSendAvailabilityText
                        }
                        onClick={(e) => {
                          e.stopPropagation()
                          sendAvailabilityText(contact.id)
                        }}
                      />
                    </div>
                  </Tippy>{" "}
                  <Button
                    size="sm"
                    label="Archive"
                    onClick={(e) => {
                      e.stopPropagation()
                      setExpanded(true)
                      setShowRecurranceOptions(true)
                    }}
                  />
                </>
              )}

            {contact.state === ContactStateEnum.Pending && (
              <>
                {!contact.currentEmail ? (
                  <ActivateButton
                    contactId={contact.id}
                    refetchQueries={refetchQueries}
                  />
                ) : (
                  <Button
                    size="sm"
                    label="Activate"
                    onClick={() => {
                      setExpanded(true)
                      setShowActivationEmail(true)
                    }}
                  />
                )}{" "}
                <ArchiveButton
                  label="Skip"
                  contactId={contact.id}
                  refetchQueries={refetchQueries}
                />
              </>
            )}

            {contact.state === ContactStateEnum.Archived &&
              !includeRecurrence && (
                <>
                  <ActivateButton
                    contactId={contact.id}
                    refetchQueries={refetchQueries}
                  />{" "}
                  <DeleteButton
                    contactId={contact.id}
                    refetchQueries={refetchQueries}
                  />
                </>
              )}

            {showActivationEmail &&
              emailPromptWhenActivated &&
              !!contact.currentEmail && (
                <EmailActivatedContactForm
                  contact={contact}
                  refetchQueries={refetchQueries}
                  onCancel={() => {
                    setExpanded(false)
                    setShowActivationEmail(false)
                  }}
                />
              )}
          </div>
        </div>

        {expanded && showRecurranceOptions && includeRecurrence && (
          <ArchiveContactForm
            contact={contact}
            refetchQueries={refetchQueries}
            onCancel={() => {
              setExpanded(false)
              setShowRecurranceOptions(false)
            }}
          />
        )}

        {expanded && !showRecurranceOptions && (
          <div className="mt-4 md:flex">
            <div className="flex-1">
              <ContactForm contact={contact} />
              <div className="mt-4 md:flex select-text">
                <strong className="w-40 block shrink-0">Their note:</strong>{" "}
                <span>{contact.contactCallNotes || "N/A"}</span>
              </div>
            </div>
            <hr className="my-4 md:hidden" />
            <div className="flex-1 md:ml-4 md:pl-4 md:border-l md:dark:border-gray-600 select-text">
              <div className="md:flex">
                <strong className="w-40 block shrink-0">Urgency:</strong>{" "}
                <span>{contact.contactUrgency || "N/A"}</span>
              </div>
              <div className="mt-2 md:flex">
                <strong className="w-40 block shrink-0">Availability:</strong>{" "}
                <span>{contact.availability || "N/A"}</span>
              </div>
              <div className="mt-2 md:flex">
                <strong className="w-40 block shrink-0">Timezone:</strong>{" "}
                <span>{contact.timezone || "N/A"}</span>
              </div>
              {contact.source && (
                <div className="mt-2 md:flex">
                  <strong className="w-40 block shrink-0">Source:</strong>{" "}
                  <span>{contact.source}</span>
                </div>
              )}
              <div className="mt-2 md:flex">
                <strong className="w-40 block shrink-0">
                  Response preference:
                </strong>{" "}
                <span className="place-self-end">
                  {contact.responsePreference}
                </span>
              </div>
              {contact.originalInput && (
                <div className="mt-2 md:flex">
                  <strong className="w-40 block shrink-0">
                    Original input:
                  </strong>{" "}
                  <span>{contact.originalInput}</span>
                </div>
              )}

              {contact.pdlEnrichment && (
                <>
                  <hr className="my-4" />
                  <div className="mt-2">
                    <strong className="underline">Enrichment data</strong>
                    &nbsp;
                    <span className="text-sm text-gray-600">
                      (match likelihood: {contact.pdlEnrichment.matchLikelihood}
                      /10)
                    </span>
                  </div>
                  <div className="mt-2 md:flex text-sm text-gray-600"></div>
                  <PdlDataEntry
                    title="Employer"
                    value={contact.pdlEnrichment.currentEmployer}
                    url={contact.pdlEnrichment.currentEmployerUrl}
                  />
                  <PdlDataEntry
                    title="Title"
                    value={contact.pdlEnrichment.currentTitle}
                  />
                  <PdlDataEntry
                    title="School"
                    value={contact.pdlEnrichment.school}
                  />
                  <PdlDataEntry
                    title="Twitter username"
                    value={contact.pdlEnrichment.twitterHandle}
                    url={contact.pdlEnrichment.twitterUrl}
                  />
                  <PdlDataEntry
                    title="Linkedin username"
                    value={contact.pdlEnrichment.linkedinHandle}
                    url={contact.pdlEnrichment.linkedinUrl}
                  />
                  <PdlDataEntry
                    title="Location"
                    value={contact.pdlEnrichment.location}
                  />
                </>
              )}
            </div>
          </div>
        )}

        {!expanded && (notes || contact.availabilityFormSubmittedAt) && (
          <div>
            <div
              className={clsx("mt-4 md:w-2/3", {
                "md:ms-12": contact.state === ContactStateEnum.Active,
              })}
            >
              {contact.state !== ContactStateEnum.Archived && notes && (
                <div className="line-clamp-3">
                  <div>{notes}</div>
                </div>
              )}
              {contact.availabilityFormSubmittedAt && (
                <small>
                  Submitted{" "}
                  {formatDistanceToNow(
                    new Date(contact.availabilityFormSubmittedAt)
                  )}{" "}
                  ago
                </small>
              )}
            </div>
          </div>
        )}

        {contact.state !== ContactStateEnum.Active && contact.recurAt && (
          <div>
            Recurs on {format(parseISO(contact.recurAt), "LLL d, yyyy")}
          </div>
        )}
      </div>
    </div>
  )
}

const PdlDataEntry = ({
  value,
  title,
  url,
}: {
  value: string | null | undefined
  title: string
  url?: string | null | undefined
}) => {
  if (!value) return null

  if (url) {
    return (
      <div className="mt-2 md:flex">
        <strong className="w-48 block shrink-0">{title}:</strong>{" "}
        <a
          className="text-blue-600 underline"
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      </div>
    )
  } else {
    return (
      <div className="mt-2 md:flex">
        <strong className="w-48 block shrink-0">{title}:</strong>{" "}
        <span>{value}</span>
      </div>
    )
  }
}

const contactAvailabilityToColor = (availability: ContactAvailabilityEnum) => {
  switch (availability) {
    case ContactAvailabilityEnum.RespondedAvailable:
      return ["bg-green-600", "Recently responded that they are available."]
    case ContactAvailabilityEnum.InWorkingHours:
      return ["bg-green-300", "They are inside of working hours."]
    case ContactAvailabilityEnum.OutsideWorkingHours:
      return ["bg-gray-200", "They are outside working hours."]
    default:
      return [
        "bg-gray-200",
        "They have not provided availability or timezone info.",
      ]
  }
}

const SEND_AVAILABILITY_TEXT = gql(`
  mutation SendAvailabilityText($input: SendAvailabilityTextInput!) {
    sendAvailabilityText(input: $input) {
      contact {
        id
        canSendAvailabilityText
      }
    }
  }
`)

const SEND_FORM_REQUEST_EMAIL = gql(`
  mutation SendFormRequestEmail($input: SendFormRequestEmailInput!) {
    sendFormRequestEmail(input: $input) {
      contact {
        id
        canSendFormRequestEmail
      }
    }
  }
`)

const MARK_CONTACT_AS_READ = gql(`
  mutation MarkContactAsRead($input: MarkContactAsReadInput!) {
    markContactAsRead(input: $input) {
      contact {
        id
        unreadUpdates
      }
    }
  }
`)
