import { Formik, Form, FormikHelpers } from "formik"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { TextField } from "~/ui/formFields/TextField"
import { Button } from "~/ui/Button"
import { customerPortalPath } from "~/common/paths"

type CurrentUserValues = {
  name?: string
  email: string
  phone?: string
}

export const AccountSettingsScreen = () => {
  const currentUser = useCurrentUser()
  const [updateCurrentUser, { loading }] = useSafeMutation(
    UPDATE_CURRENT_USER_MUTATION
  )

  const initialValues: CurrentUserValues = {
    name: currentUser.name ?? "",
    email: currentUser.email ?? "",
    phone: currentUser.phone ?? "",
  }

  const onSubmit = async (
    values: CurrentUserValues,
    { setFieldError }: FormikHelpers<CurrentUserValues>
  ) => {
    const { errors } = await updateCurrentUser({
      variables: {
        input: {
          ...values,
        },
      },
    })

    if (errors) {
      displayErrors(errors, setFieldError)
    } else {
      toast.success("Account updated")
    }
  }

  return (
    <div className="max-w-xl">
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {() => (
          <Form>
            <TextField name="name" label="Name" />
            <TextField
              name="email"
              label="Email"
              labelClassName="mt-4"
              required
            />
            <TextField
              name="phone"
              type="tel"
              label="Phone"
              labelClassName="mt-4"
            />

            <div className="mt-8">
              <Button
                type="submit"
                label="Update"
                className="w-full"
                disabled={loading}
              />
            </div>
          </Form>
        )}
      </Formik>
      <hr className="my-8" />
      {currentUser.hasStripeCustomer && (
        <>
          <h2 className="mb-4">Subscription Settings</h2>
          <p>
            {currentUser.canceledButPaidThru ? (
              <>
                Your subscription will cancel at the end of the billing period.
              </>
            ) : (
              <>
                Your subscription is{" "}
                <strong>
                  {currentUser.subscriptionStatus
                    ?.replace("_", " ")
                    .toLowerCase()}
                  .
                </strong>
              </>
            )}
          </p>
          <p className="mb-4">
            Manage your subscription in the Stripe Dashboard.
          </p>
          <p>
            <a
              rel="noreferrer"
              target="_blank"
              href={customerPortalPath.pattern}
            >
              Manage Subscription &gt;
            </a>
          </p>
        </>
      )}
    </div>
  )
}

const UPDATE_CURRENT_USER_MUTATION = gql(`
  mutation UpdateCurrentUser($input: UpdateCurrentUserInput!) {
    updateCurrentUser(input: $input) {
      currentUser {
        id
        email
        name
        phone
      }
    }
  }
`)
