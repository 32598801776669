export const PrivacyScreen = () => {
  return (
    <div className="max-w-3xl mx-auto">
      <h2>Privacy Policy</h2>
      <p className="mt-6">
        <i>Last Updated: November 15, 2023</i>
      </p>
      <ol className="list-decimal ms-10 mt-6">
        <li>
          <strong>Introduction</strong>
          <p className="mt-6">
            Call List is committed to protecting your privacy. This Privacy
            Policy explains how your personal information is collected, used,
            and disclosed by Call List.
          </p>
        </li>
        <li className="mt-6">
          <strong>Information Collection</strong>
          <p className="mt-6">
            In order to provide you with services, Call List collects your name,
            email, phone number, availability, notes about you and more.
          </p>
        </li>
        <li className="mt-6">
          <strong>Use of Information</strong>
          <p className="mt-6">
            Call List uses the information to facilitate the Call List service,
            which allows individuals to prioritize lists of who to call, and
            then make efficient connections with those people to have phone
            calls.
          </p>
        </li>
        <li className="mt-6">
          <strong>Information Sharing</strong>
          <p className="mt-6">
            We will share your personal information with third parties as
            necessary to provide our service or as required by law. We will use
            your information to improve Call List services, tell you about
            features and call list upgrades, and communicate with you. If you
            don’t like this don’t use call list.
          </p>
        </li>
        <li className="mt-6">
          <strong>Service Fees</strong>
          <ol className="list-alpha ms-10 mt-6">
            <li>
              The annual fee for using our service is noted in the Terms of
              Service.
            </li>
            <li>
              The fee is non-refundable, even if the service is discontinued or
              if your access is revoked.
            </li>
          </ol>
        </li>
        <li className="mt-6">
          <strong>International and California Users</strong>
          <p className="mt-6">
            Call List does not comply with GDPR or CCPA. do not use Call List if
            you live in a place where GDPR or a GDPR like legal framework is in
            place, and do not use call list if you consider yourself a
            Califronia resident for the purposes of CCPA.
          </p>
        </li>
        <li className="mt-6">
          <strong>Changes to This Policy</strong>
          <p className="mt-6">
            We change this policy from time to time and will update the date and
            time stamp on this page when it changes. It is your responsibility
            to track changes to this page.
          </p>
        </li>
      </ol>
    </div>
  )
}
