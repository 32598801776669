import { useState, useEffect } from "react"
import { useQuery } from "@apollo/client"
import toast from "react-hot-toast"
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort"
import arrayMove from "array-move"
import clsx from "clsx"
import { gql } from "~/__generated__"
import { ActiveContactsQuery } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Error } from "~/ui/Error"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { ContactCard } from "~/contacts/ContactCard"
import { AddContactForm } from "~/contacts/AddContactForm"

export const ActiveContactsScreen = () => {
  const [updatePositions, { loading: updatingPositions }] = useSafeMutation(
    SORT_CONTACTS_MUTATION
  )
  const { data, loading, error } = useQuery(ACTIVE_CONTACTS_QUERY)

  const [activeContacts, setActiveContacts] = useState<
    ActiveContactsQuery["activeContacts"]["nodes"]
  >([])

  useEffect(() => {
    if (data?.activeContacts.nodes) {
      setActiveContacts(data.activeContacts.nodes)
    }
  }, [data?.activeContacts.nodes, setActiveContacts])

  if (loading && !data) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading templates" />

  const onSortEnd = async (oldIndex: number, newIndex: number) => {
    if (updatingPositions) return

    const prevOrder = activeContacts
    const nextOrder = arrayMove(activeContacts, oldIndex, newIndex)

    setActiveContacts(nextOrder)

    const { data } = await updatePositions({
      variables: {
        input: {
          contactIds: nextOrder.map((ac) => ac.id),
        },
      },
    })

    if (!data?.updateContactPositions.contacts.length) {
      toast.error("Error updating order")
      setActiveContacts(prevOrder)
    }
  }

  return (
    <div>
      <AddContactForm />

      {data.activeContacts.nodes.length > 0 ? (
        <SortableList
          onSortEnd={onSortEnd}
          className={clsx("list mt-8", updatingPositions && "cursor-wait")}
          draggedItemClassName="!cursor-grabbing"
          lockAxis="y"
          allowDrag={!updatingPositions}
        >
          {activeContacts.map((contact) => (
            <SortableItem key={contact.id}>
              <div className="select-none group">
                <ContactCard
                  includeRecurrence
                  className="mt-4"
                  contact={contact}
                  sortableKnob={
                    <SortableKnob>
                      <div className="inline-block md:hidden cursor-grab text-black/30 md:group-hover:inline-block tracking-wide">
                        <div className="text-xs leading-[8px]">
                          &bull;&bull;&bull;
                        </div>
                        <div className="text-xs leading-[8px]">
                          &bull;&bull;&bull;
                        </div>
                      </div>
                    </SortableKnob>
                  }
                />
              </div>
            </SortableItem>
          ))}
        </SortableList>
      ) : (
        <p className="mt-8">Add a contact to get started.</p>
      )}
    </div>
  )
}

gql(`
  fragment Contact_ContactList on Contact {
    id
    availability
    availabilityFormSubmittedAt
    canSendAvailabilityText
    canSendFormRequestEmail
    contactCallNotes
    contactUrgency
    currentAvailability
    currentEmail
    currentPhone
    firstName
    name
    notes
    originalInput
    recurAt
    responsePreference
    source
    state
    timezone
    unreadUpdates

    pdlEnrichment {
      id
      currentEmployer
      currentEmployerUrl
      currentTitle
      school
      twitterHandle
      twitterUrl
      linkedinHandle
      linkedinUrl
      location
      matchLikelihood
    }
  }
`)

export const ACTIVE_CONTACTS_QUERY = gql(`
  query ActiveContacts {
    activeContacts {
      nodes {
        id
        ...Contact_ContactList
      }
    }
  }
`)

const SORT_CONTACTS_MUTATION = gql(`
  mutation UpdateContactPositions($input: UpdateContactPositionsInput!) {
    updateContactPositions(input: $input) {
      contacts {
        id
      }
    }
  }
`)
