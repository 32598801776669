import { ButtonHTMLAttributes } from "react"
import { useNavigate } from "react-router-dom"
import clsx from "clsx"

type ButtonProps = {
  label?: string
  href?: string
  size?: "xs" | "sm" | "md"
  color?: "primary" | "secondary" | "success" | "danger"
} & ButtonHTMLAttributes<HTMLButtonElement>

export const Button = ({
  label,
  disabled,
  size = "md",
  type = "button",
  color = "primary",
  className,
  href,
  ...buttonProps
}: ButtonProps) => {
  const navigate = useNavigate()

  const onClick = () => {
    if (!href) return
    navigate(href)
  }

  let sizeClasses = ""
  switch (size) {
    case "xs":
      sizeClasses = "text-sm py-1 px-2"
      break
    case "sm":
      sizeClasses = "py-2 px-4"
      break
    default:
      sizeClasses = "py-4 px-6"
  }

  let colorClasses = ""
  switch (color) {
    case "primary":
      colorClasses = "bg-pink-600"
      break
    case "secondary":
      colorClasses = "bg-slate-600"
      break
    case "success":
      colorClasses = "bg-green-600"
      break
    case "danger":
      colorClasses = "bg-red-700"
      break
    default:
      throw new Error("invariant")
  }

  return (
    <button
      className={clsx(
        "rounded-sm border-0 no-underline text-left text-white hover:text-white focus:text-white",
        "hover:cursor-pointer hover:underline",
        "focus:cursor-pointer focus:underline",
        "disabled:bg-gray-400 disabled:text-white/70 disabled:cursor-not-allowed",
        colorClasses,
        sizeClasses,
        className
      )}
      type={type}
      disabled={disabled}
      onClick={onClick}
      {...buttonProps}
    >
      {label ?? "Submit"}
    </button>
  )
}
