/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation ApproveTemplate($input: ApprovePendingTemplateInput!) {\n    adminApprovePendingTemplate(input: $input) {\n      user {\n        id\n        pendingEmailTemplate\n        pendingNoteTemplate\n        pendingTextTemplate\n        pendingActivationEmailTemplate\n      }\n    }\n  }\n": types.ApproveTemplateDocument,
    "\n  mutation RejectTemplate($input: RejectPendingTemplateInput!) {\n    adminRejectPendingTemplate(input: $input) {\n      user {\n        id\n        pendingEmailTemplate\n        pendingNoteTemplate\n        pendingTextTemplate\n        pendingActivationEmailTemplate\n      }\n    }\n  }\n": types.RejectTemplateDocument,
    "\n  query CurrentUserProvider {\n    currentUser {\n      id\n      isAdmin\n      inboundEmail\n      callListEmail\n      callListUrl\n      formPath\n      name\n      email\n      phone\n      hasPaidAccess\n      subscriptionStatus\n      canceledButPaidThru\n      dailyCallListEnabled\n      emailAvailabilityFormEnabled\n      requireCallNotes\n      emailTemplate\n      pendingEmailTemplate\n      emailCopy\n      noteTemplate\n      pendingNoteTemplate\n      noteCopy\n      textTemplate\n      pendingTextTemplate\n      textCopy\n      activationEmailTemplate\n      pendingActivationEmailTemplate\n      hasStripeCustomer\n      enrichment\n      __typename\n    }\n  }\n": types.CurrentUserProviderDocument,
    "\n  mutation ActivateContact($input: ActivateContactInput!) {\n    activateContact(input: $input) {\n      contact {\n        id\n        name\n        state\n        contactCallNotes\n        currentEmail\n        currentPhone\n        notes\n      }\n    }\n  }\n": types.ActivateContactDocument,
    "\n  mutation ArchiveContact($input: ArchiveContactInput!) {\n    archiveContact(input: $input) {\n      contact {\n        id\n        name\n        state\n        contactCallNotes\n        currentEmail\n        currentPhone\n        notes\n      }\n    }\n  }\n": types.ArchiveContactDocument,
    "\n  mutation DeleteContact($input: DeleteContactInput!) {\n    deleteContact(input: $input) {\n      success\n    }\n  }\n": types.DeleteContactDocument,
    "\n  mutation AddContact($input: AddContactInput!) {\n    addContact(input: $input) {\n      contact {\n        id\n        name\n        state\n      }\n    }\n  }\n": types.AddContactDocument,
    "\n  query SearchContacts($query: String!) {\n    searchContacts(query: $query) {\n      nodes {\n        id\n        name\n        currentEmail\n        currentPhone\n        state\n      }\n    }\n  }\n": types.SearchContactsDocument,
    "\n  mutation SetRecurrence($input: UpdateContactInput!) {\n    updateContact(input: $input) {\n      contact {\n        id\n        recurAt\n      }\n    }\n  }\n": types.SetRecurrenceDocument,
    "\n  mutation AddAvailability($input: AddAvailabilityInput!) {\n    addAvailability(input: $input) {\n      contact {\n        id\n      }\n    }\n  }\n": types.AddAvailabilityDocument,
    "\n  mutation SendAvailabilityText($input: SendAvailabilityTextInput!) {\n    sendAvailabilityText(input: $input) {\n      contact {\n        id\n        canSendAvailabilityText\n      }\n    }\n  }\n": types.SendAvailabilityTextDocument,
    "\n  mutation SendFormRequestEmail($input: SendFormRequestEmailInput!) {\n    sendFormRequestEmail(input: $input) {\n      contact {\n        id\n        canSendFormRequestEmail\n      }\n    }\n  }\n": types.SendFormRequestEmailDocument,
    "\n  mutation MarkContactAsRead($input: MarkContactAsReadInput!) {\n    markContactAsRead(input: $input) {\n      contact {\n        id\n        unreadUpdates\n      }\n    }\n  }\n": types.MarkContactAsReadDocument,
    "\n  mutation UpdateContact($input: UpdateContactInput!) {\n    updateContact(input: $input) {\n      contact {\n        id\n        availability\n        contactCallNotes\n        contactUrgency\n        currentEmail\n        currentPhone\n        name\n        notes\n        state\n        timezone\n      }\n    }\n  }\n": types.UpdateContactDocument,
    "\n  mutation SendActivationEmail($input: SendActivationEmailInput!) {\n    sendActivationEmail(input: $input) {\n      success\n    }\n  }\n": types.SendActivationEmailDocument,
    "\n  query ContactLayoutCounts {\n    pendingContacts {\n      totalCount\n    }\n  }\n": types.ContactLayoutCountsDocument,
    "\n  mutation UpdateCurrentUser($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      currentUser {\n        id\n        email\n        name\n        phone\n      }\n    }\n  }\n": types.UpdateCurrentUserDocument,
    "\n  fragment Contact_ContactList on Contact {\n    id\n    availability\n    availabilityFormSubmittedAt\n    canSendAvailabilityText\n    canSendFormRequestEmail\n    contactCallNotes\n    contactUrgency\n    currentAvailability\n    currentEmail\n    currentPhone\n    firstName\n    name\n    notes\n    originalInput\n    recurAt\n    responsePreference\n    source\n    state\n    timezone\n    unreadUpdates\n\n    pdlEnrichment {\n      id\n      currentEmployer\n      currentEmployerUrl\n      currentTitle\n      school\n      twitterHandle\n      twitterUrl\n      linkedinHandle\n      linkedinUrl\n      location\n      matchLikelihood\n    }\n  }\n": types.Contact_ContactListFragmentDoc,
    "\n  query ActiveContacts {\n    activeContacts {\n      nodes {\n        id\n        ...Contact_ContactList\n      }\n    }\n  }\n": types.ActiveContactsDocument,
    "\n  mutation UpdateContactPositions($input: UpdateContactPositionsInput!) {\n    updateContactPositions(input: $input) {\n      contacts {\n        id\n      }\n    }\n  }\n": types.UpdateContactPositionsDocument,
    "\n  query ArchivedContacts($query: String, $after: String) {\n    archivedContacts(query: $query, first: 20, after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...Contact_ContactList\n        }\n      }\n      totalCount\n    }\n  }\n": types.ArchivedContactsDocument,
    "\n  query UserForFormQuery($formPath: String!) {\n    userForAvailabilityForm(formPath: $formPath) {\n      id\n      name\n      requireCallNotes\n    }\n  }\n": types.UserForFormQueryDocument,
    "\n  mutation CreateEmailUnsubscribe($input: CreateEmailUnsubscribeInput!) {\n    createEmailUnsubscribe(input: $input) {\n      success\n    }\n  }\n": types.CreateEmailUnsubscribeDocument,
    "\n  mutation InitializeCheckout($input: InitializeCheckoutInput!) {\n    initializeCheckout(input: $input) {\n      clientSecret\n      afterSubscribeUrl\n      formattedPrice\n      paymentInterval\n    }\n  }\n": types.InitializeCheckoutDocument,
    "\n  query PendingContacts {\n    pendingContacts {\n      nodes {\n        id\n        ...Contact_ContactList\n      }\n    }\n  }\n": types.PendingContactsDocument,
    "\n  mutation UpdateCurrentUserHandles($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      currentUser {\n        id\n        inboundEmail\n        callListEmail\n        callListUrl\n        dailyCallListEnabled\n        emailAvailabilityFormEnabled\n        requireCallNotes\n      }\n    }\n  }\n": types.UpdateCurrentUserHandlesDocument,
    "\n  mutation SendMagicLink($input: SendMagicLinkInput!) {\n    sendMagicLink(input: $input) {\n      success\n    }\n  }\n": types.SendMagicLinkDocument,
    "\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      user {\n        id\n        email\n      }\n    }\n  }\n": types.CreateUserDocument,
    "\n  query ContactUploads {\n    contactUploads {\n      nodes {\n        id\n        filename\n        createdAt\n        state\n        contactCount\n      }\n    }\n  }\n": types.ContactUploadsDocument,
    "\n  mutation AdminCreateUserMutation($input: UserCreateInput!) {\n    adminUserCreate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n": types.AdminCreateUserMutationDocument,
    "\n  query AdminUserRoute($userId: ID!) {\n    adminUser(userId: $userId) {\n      id\n      name\n      email\n      phone\n      isAdmin\n      hasCompAccess\n      enrichment\n      referralCode\n      overrideStripePriceId\n      pendingEmailTemplate\n      pendingNoteTemplate\n      pendingTextTemplate\n      pendingActivationEmailTemplate\n    }\n  }\n": types.AdminUserRouteDocument,
    "\n  mutation AdminUpdateUserMutation($input: UserUpdateInput!) {\n    adminUserUpdate(input: $input) {\n      user {\n        id\n        email\n        name\n        phone\n        isAdmin\n        hasCompAccess\n        enrichment\n        referralCode\n        overrideStripePriceId\n      }\n    }\n  }\n": types.AdminUpdateUserMutationDocument,
    "\n  query AdminUsersRoute {\n    adminUsers {\n      nodes {\n        id\n        name\n        email\n        isAdmin\n        inboundEmail\n        createdAt\n        subscriptionStatus\n        hasCompAccess\n        hasPendingTemplateUpdates\n        contactsCount\n        enrichment\n      }\n    }\n  }\n": types.AdminUsersRouteDocument,
    "\n  mutation UpdateCurrentUserTemplates($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      currentUser {\n        id\n        pendingEmailTemplate\n        pendingNoteTemplate\n        pendingTextTemplate\n        pendingActivationEmailTemplate\n      }\n    }\n  }\n": types.UpdateCurrentUserTemplatesDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ApproveTemplate($input: ApprovePendingTemplateInput!) {\n    adminApprovePendingTemplate(input: $input) {\n      user {\n        id\n        pendingEmailTemplate\n        pendingNoteTemplate\n        pendingTextTemplate\n        pendingActivationEmailTemplate\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ApproveTemplate($input: ApprovePendingTemplateInput!) {\n    adminApprovePendingTemplate(input: $input) {\n      user {\n        id\n        pendingEmailTemplate\n        pendingNoteTemplate\n        pendingTextTemplate\n        pendingActivationEmailTemplate\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RejectTemplate($input: RejectPendingTemplateInput!) {\n    adminRejectPendingTemplate(input: $input) {\n      user {\n        id\n        pendingEmailTemplate\n        pendingNoteTemplate\n        pendingTextTemplate\n        pendingActivationEmailTemplate\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RejectTemplate($input: RejectPendingTemplateInput!) {\n    adminRejectPendingTemplate(input: $input) {\n      user {\n        id\n        pendingEmailTemplate\n        pendingNoteTemplate\n        pendingTextTemplate\n        pendingActivationEmailTemplate\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CurrentUserProvider {\n    currentUser {\n      id\n      isAdmin\n      inboundEmail\n      callListEmail\n      callListUrl\n      formPath\n      name\n      email\n      phone\n      hasPaidAccess\n      subscriptionStatus\n      canceledButPaidThru\n      dailyCallListEnabled\n      emailAvailabilityFormEnabled\n      requireCallNotes\n      emailTemplate\n      pendingEmailTemplate\n      emailCopy\n      noteTemplate\n      pendingNoteTemplate\n      noteCopy\n      textTemplate\n      pendingTextTemplate\n      textCopy\n      activationEmailTemplate\n      pendingActivationEmailTemplate\n      hasStripeCustomer\n      enrichment\n      __typename\n    }\n  }\n"): (typeof documents)["\n  query CurrentUserProvider {\n    currentUser {\n      id\n      isAdmin\n      inboundEmail\n      callListEmail\n      callListUrl\n      formPath\n      name\n      email\n      phone\n      hasPaidAccess\n      subscriptionStatus\n      canceledButPaidThru\n      dailyCallListEnabled\n      emailAvailabilityFormEnabled\n      requireCallNotes\n      emailTemplate\n      pendingEmailTemplate\n      emailCopy\n      noteTemplate\n      pendingNoteTemplate\n      noteCopy\n      textTemplate\n      pendingTextTemplate\n      textCopy\n      activationEmailTemplate\n      pendingActivationEmailTemplate\n      hasStripeCustomer\n      enrichment\n      __typename\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ActivateContact($input: ActivateContactInput!) {\n    activateContact(input: $input) {\n      contact {\n        id\n        name\n        state\n        contactCallNotes\n        currentEmail\n        currentPhone\n        notes\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ActivateContact($input: ActivateContactInput!) {\n    activateContact(input: $input) {\n      contact {\n        id\n        name\n        state\n        contactCallNotes\n        currentEmail\n        currentPhone\n        notes\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ArchiveContact($input: ArchiveContactInput!) {\n    archiveContact(input: $input) {\n      contact {\n        id\n        name\n        state\n        contactCallNotes\n        currentEmail\n        currentPhone\n        notes\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ArchiveContact($input: ArchiveContactInput!) {\n    archiveContact(input: $input) {\n      contact {\n        id\n        name\n        state\n        contactCallNotes\n        currentEmail\n        currentPhone\n        notes\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteContact($input: DeleteContactInput!) {\n    deleteContact(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteContact($input: DeleteContactInput!) {\n    deleteContact(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddContact($input: AddContactInput!) {\n    addContact(input: $input) {\n      contact {\n        id\n        name\n        state\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddContact($input: AddContactInput!) {\n    addContact(input: $input) {\n      contact {\n        id\n        name\n        state\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchContacts($query: String!) {\n    searchContacts(query: $query) {\n      nodes {\n        id\n        name\n        currentEmail\n        currentPhone\n        state\n      }\n    }\n  }\n"): (typeof documents)["\n  query SearchContacts($query: String!) {\n    searchContacts(query: $query) {\n      nodes {\n        id\n        name\n        currentEmail\n        currentPhone\n        state\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetRecurrence($input: UpdateContactInput!) {\n    updateContact(input: $input) {\n      contact {\n        id\n        recurAt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetRecurrence($input: UpdateContactInput!) {\n    updateContact(input: $input) {\n      contact {\n        id\n        recurAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddAvailability($input: AddAvailabilityInput!) {\n    addAvailability(input: $input) {\n      contact {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddAvailability($input: AddAvailabilityInput!) {\n    addAvailability(input: $input) {\n      contact {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendAvailabilityText($input: SendAvailabilityTextInput!) {\n    sendAvailabilityText(input: $input) {\n      contact {\n        id\n        canSendAvailabilityText\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SendAvailabilityText($input: SendAvailabilityTextInput!) {\n    sendAvailabilityText(input: $input) {\n      contact {\n        id\n        canSendAvailabilityText\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendFormRequestEmail($input: SendFormRequestEmailInput!) {\n    sendFormRequestEmail(input: $input) {\n      contact {\n        id\n        canSendFormRequestEmail\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SendFormRequestEmail($input: SendFormRequestEmailInput!) {\n    sendFormRequestEmail(input: $input) {\n      contact {\n        id\n        canSendFormRequestEmail\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation MarkContactAsRead($input: MarkContactAsReadInput!) {\n    markContactAsRead(input: $input) {\n      contact {\n        id\n        unreadUpdates\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation MarkContactAsRead($input: MarkContactAsReadInput!) {\n    markContactAsRead(input: $input) {\n      contact {\n        id\n        unreadUpdates\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateContact($input: UpdateContactInput!) {\n    updateContact(input: $input) {\n      contact {\n        id\n        availability\n        contactCallNotes\n        contactUrgency\n        currentEmail\n        currentPhone\n        name\n        notes\n        state\n        timezone\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateContact($input: UpdateContactInput!) {\n    updateContact(input: $input) {\n      contact {\n        id\n        availability\n        contactCallNotes\n        contactUrgency\n        currentEmail\n        currentPhone\n        name\n        notes\n        state\n        timezone\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendActivationEmail($input: SendActivationEmailInput!) {\n    sendActivationEmail(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation SendActivationEmail($input: SendActivationEmailInput!) {\n    sendActivationEmail(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ContactLayoutCounts {\n    pendingContacts {\n      totalCount\n    }\n  }\n"): (typeof documents)["\n  query ContactLayoutCounts {\n    pendingContacts {\n      totalCount\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateCurrentUser($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      currentUser {\n        id\n        email\n        name\n        phone\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCurrentUser($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      currentUser {\n        id\n        email\n        name\n        phone\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Contact_ContactList on Contact {\n    id\n    availability\n    availabilityFormSubmittedAt\n    canSendAvailabilityText\n    canSendFormRequestEmail\n    contactCallNotes\n    contactUrgency\n    currentAvailability\n    currentEmail\n    currentPhone\n    firstName\n    name\n    notes\n    originalInput\n    recurAt\n    responsePreference\n    source\n    state\n    timezone\n    unreadUpdates\n\n    pdlEnrichment {\n      id\n      currentEmployer\n      currentEmployerUrl\n      currentTitle\n      school\n      twitterHandle\n      twitterUrl\n      linkedinHandle\n      linkedinUrl\n      location\n      matchLikelihood\n    }\n  }\n"): (typeof documents)["\n  fragment Contact_ContactList on Contact {\n    id\n    availability\n    availabilityFormSubmittedAt\n    canSendAvailabilityText\n    canSendFormRequestEmail\n    contactCallNotes\n    contactUrgency\n    currentAvailability\n    currentEmail\n    currentPhone\n    firstName\n    name\n    notes\n    originalInput\n    recurAt\n    responsePreference\n    source\n    state\n    timezone\n    unreadUpdates\n\n    pdlEnrichment {\n      id\n      currentEmployer\n      currentEmployerUrl\n      currentTitle\n      school\n      twitterHandle\n      twitterUrl\n      linkedinHandle\n      linkedinUrl\n      location\n      matchLikelihood\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ActiveContacts {\n    activeContacts {\n      nodes {\n        id\n        ...Contact_ContactList\n      }\n    }\n  }\n"): (typeof documents)["\n  query ActiveContacts {\n    activeContacts {\n      nodes {\n        id\n        ...Contact_ContactList\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateContactPositions($input: UpdateContactPositionsInput!) {\n    updateContactPositions(input: $input) {\n      contacts {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateContactPositions($input: UpdateContactPositionsInput!) {\n    updateContactPositions(input: $input) {\n      contacts {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ArchivedContacts($query: String, $after: String) {\n    archivedContacts(query: $query, first: 20, after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...Contact_ContactList\n        }\n      }\n      totalCount\n    }\n  }\n"): (typeof documents)["\n  query ArchivedContacts($query: String, $after: String) {\n    archivedContacts(query: $query, first: 20, after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...Contact_ContactList\n        }\n      }\n      totalCount\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UserForFormQuery($formPath: String!) {\n    userForAvailabilityForm(formPath: $formPath) {\n      id\n      name\n      requireCallNotes\n    }\n  }\n"): (typeof documents)["\n  query UserForFormQuery($formPath: String!) {\n    userForAvailabilityForm(formPath: $formPath) {\n      id\n      name\n      requireCallNotes\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateEmailUnsubscribe($input: CreateEmailUnsubscribeInput!) {\n    createEmailUnsubscribe(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation CreateEmailUnsubscribe($input: CreateEmailUnsubscribeInput!) {\n    createEmailUnsubscribe(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation InitializeCheckout($input: InitializeCheckoutInput!) {\n    initializeCheckout(input: $input) {\n      clientSecret\n      afterSubscribeUrl\n      formattedPrice\n      paymentInterval\n    }\n  }\n"): (typeof documents)["\n  mutation InitializeCheckout($input: InitializeCheckoutInput!) {\n    initializeCheckout(input: $input) {\n      clientSecret\n      afterSubscribeUrl\n      formattedPrice\n      paymentInterval\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PendingContacts {\n    pendingContacts {\n      nodes {\n        id\n        ...Contact_ContactList\n      }\n    }\n  }\n"): (typeof documents)["\n  query PendingContacts {\n    pendingContacts {\n      nodes {\n        id\n        ...Contact_ContactList\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateCurrentUserHandles($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      currentUser {\n        id\n        inboundEmail\n        callListEmail\n        callListUrl\n        dailyCallListEnabled\n        emailAvailabilityFormEnabled\n        requireCallNotes\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCurrentUserHandles($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      currentUser {\n        id\n        inboundEmail\n        callListEmail\n        callListUrl\n        dailyCallListEnabled\n        emailAvailabilityFormEnabled\n        requireCallNotes\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendMagicLink($input: SendMagicLinkInput!) {\n    sendMagicLink(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation SendMagicLink($input: SendMagicLinkInput!) {\n    sendMagicLink(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      user {\n        id\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      user {\n        id\n        email\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ContactUploads {\n    contactUploads {\n      nodes {\n        id\n        filename\n        createdAt\n        state\n        contactCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query ContactUploads {\n    contactUploads {\n      nodes {\n        id\n        filename\n        createdAt\n        state\n        contactCount\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AdminCreateUserMutation($input: UserCreateInput!) {\n    adminUserCreate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AdminCreateUserMutation($input: UserCreateInput!) {\n    adminUserCreate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminUserRoute($userId: ID!) {\n    adminUser(userId: $userId) {\n      id\n      name\n      email\n      phone\n      isAdmin\n      hasCompAccess\n      enrichment\n      referralCode\n      overrideStripePriceId\n      pendingEmailTemplate\n      pendingNoteTemplate\n      pendingTextTemplate\n      pendingActivationEmailTemplate\n    }\n  }\n"): (typeof documents)["\n  query AdminUserRoute($userId: ID!) {\n    adminUser(userId: $userId) {\n      id\n      name\n      email\n      phone\n      isAdmin\n      hasCompAccess\n      enrichment\n      referralCode\n      overrideStripePriceId\n      pendingEmailTemplate\n      pendingNoteTemplate\n      pendingTextTemplate\n      pendingActivationEmailTemplate\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AdminUpdateUserMutation($input: UserUpdateInput!) {\n    adminUserUpdate(input: $input) {\n      user {\n        id\n        email\n        name\n        phone\n        isAdmin\n        hasCompAccess\n        enrichment\n        referralCode\n        overrideStripePriceId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AdminUpdateUserMutation($input: UserUpdateInput!) {\n    adminUserUpdate(input: $input) {\n      user {\n        id\n        email\n        name\n        phone\n        isAdmin\n        hasCompAccess\n        enrichment\n        referralCode\n        overrideStripePriceId\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminUsersRoute {\n    adminUsers {\n      nodes {\n        id\n        name\n        email\n        isAdmin\n        inboundEmail\n        createdAt\n        subscriptionStatus\n        hasCompAccess\n        hasPendingTemplateUpdates\n        contactsCount\n        enrichment\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminUsersRoute {\n    adminUsers {\n      nodes {\n        id\n        name\n        email\n        isAdmin\n        inboundEmail\n        createdAt\n        subscriptionStatus\n        hasCompAccess\n        hasPendingTemplateUpdates\n        contactsCount\n        enrichment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateCurrentUserTemplates($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      currentUser {\n        id\n        pendingEmailTemplate\n        pendingNoteTemplate\n        pendingTextTemplate\n        pendingActivationEmailTemplate\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCurrentUserTemplates($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      currentUser {\n        id\n        pendingEmailTemplate\n        pendingNoteTemplate\n        pendingTextTemplate\n        pendingActivationEmailTemplate\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;