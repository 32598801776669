export async function loggingFetch(
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> {
  // @ts-ignore
  const body = JSON.parse(init?.body ?? "{}")

  const start = Date.now()
  console.log(
    `${new Date().toISOString().slice(-13)} 📡 Sending ${
      body.operationName
    } request`
  )
  const response = await fetch(input, init)
  console.log(
    `${new Date().toISOString().slice(-13)} 📡 Received ${
      body.operationName
    } response in ${Date.now() - start}ms`
  )

  return {
    ...response,

    async text() {
      const start = Date.now()
      const result = await response.text()
      console.log(
        `${new Date().toISOString().slice(-13)} ⚙️  Read ${
          body.operationName
        } response body in ${Date.now() - start}ms (${result.length} bytes)`
      )
      return result
    },
  }
}
