import { Outlet, useNavigate } from "react-router-dom"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"
import { useEffect } from "react"
import { landingPath } from "~/common/paths"

export const RequireNoSubscription = () => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUserMaybe()

  useEffect(() => {
    if (currentUser && currentUser.hasPaidAccess) {
      navigate(landingPath.pattern)
    }
  })

  return <Outlet />
}
