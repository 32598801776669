import { DocumentNode } from "graphql"
import { toast } from "react-hot-toast"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"

interface UseActivateContactProps {
  refetchQueries?: DocumentNode[]
}

export const useActivateContact = ({
  refetchQueries,
}: UseActivateContactProps) => {
  const [mutate, { loading }] = useSafeMutation(ACTIVATE_CONTACT_MUTATION, {
    refetchQueries,
  })

  const activateContact = async (id: string) => {
    const { errors } = await mutate({
      variables: {
        input: {
          id,
        },
      },
    })

    if (errors) {
      console.error(errors)
      toast.error("Activating failed, please try again")
    }
  }

  return { activateContact, loading }
}

const ACTIVATE_CONTACT_MUTATION = gql(`
  mutation ActivateContact($input: ActivateContactInput!) {
    activateContact(input: $input) {
      contact {
        id
        name
        state
        contactCallNotes
        currentEmail
        currentPhone
        notes
      }
    }
  }
`)
