import clsx from "clsx"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { AdminUserRouteQuery, TemplateTypeEnum } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Fieldset } from "~/ui/Fieldset"
import { Button } from "~/ui/Button"

interface AdminUserTemplatesFormProps {
  user: AdminUserRouteQuery["adminUser"]
}

export const AdminUserTemplatesForm = ({
  user,
}: AdminUserTemplatesFormProps) => {
  const [approveTemplateMutation, { loading: approving }] = useSafeMutation(
    APPROVE_TEMPLATE_MUTATION
  )
  const [rejectTemplateMutation, { loading: rejecting }] = useSafeMutation(
    REJECT_TEMPLATE_MUTATION
  )

  const pendingTemplates = [
    {
      label: "Email template",
      value: user.pendingEmailTemplate,
      key: TemplateTypeEnum.Email,
    },
    {
      label: "Note template",
      value: user.pendingNoteTemplate,
      key: TemplateTypeEnum.Note,
    },
    {
      label: "Text template",
      value: user.pendingTextTemplate,
      key: TemplateTypeEnum.Text,
    },
    {
      label: "Activation email template",
      value: user.pendingActivationEmailTemplate,
      key: TemplateTypeEnum.ActivationEmail,
    },
  ].filter((template) => template.value && template.value !== "")

  const onApprove = async (templateType: TemplateTypeEnum) => {
    const { errors } = await approveTemplateMutation({
      variables: { input: { userId: user.id, templateType } },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      toast.success("Template approved and user notified")
    }
  }

  const onReject = async (templateType: TemplateTypeEnum) => {
    const { errors } = await rejectTemplateMutation({
      variables: { input: { userId: user.id, templateType } },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      toast.success("Template rejected and user notified")
    }
  }

  return (
    <Fieldset legend="Templates pending approval" className="mb-8">
      {pendingTemplates.map((template, index) => (
        <div key={template.key} className={clsx(index > 0 && "mt-8")}>
          <span className="block font-bold">{template.label}</span>
          <pre className="whitespace-pre-line w-full border-2 border-black dark:border-white dark:bg-black p-3 py-2 bg-gray-100 dark:bg-gray-800">
            {template.value}
          </pre>
          <div className="flex gap-2 mt-2">
            <Button
              size="sm"
              label="Approve"
              color="success"
              className="flex-1"
              onClick={() => onApprove(template.key)}
              disabled={approving}
            />
            <Button
              size="sm"
              label="Reject"
              color="danger"
              className="flex-1"
              onClick={() => onReject(template.key)}
              disabled={rejecting}
            />
          </div>
        </div>
      ))}
    </Fieldset>
  )
}

const APPROVE_TEMPLATE_MUTATION = gql(`
  mutation ApproveTemplate($input: ApprovePendingTemplateInput!) {
    adminApprovePendingTemplate(input: $input) {
      user {
        id
        pendingEmailTemplate
        pendingNoteTemplate
        pendingTextTemplate
        pendingActivationEmailTemplate
      }
    }
  }
`)

const REJECT_TEMPLATE_MUTATION = gql(`
  mutation RejectTemplate($input: RejectPendingTemplateInput!) {
    adminRejectPendingTemplate(input: $input) {
      user {
        id
        pendingEmailTemplate
        pendingNoteTemplate
        pendingTextTemplate
        pendingActivationEmailTemplate
      }
    }
  }
`)
