export const TermsScreen = () => {
  return (
    <div className="max-w-3xl mx-auto">
      <h2>Terms & Conditions</h2>
      <p className="mt-6">
        <i>Last Updated: November 15, 2023</i>
      </p>
      <ol className="list-decimal ms-10 mt-6">
        <li>
          <strong>Introduction</strong>
          <p className="mt-6">
            Welcome to Call List, a platform dedicated to enabling users to make
            phone calls to each other. By accessing or using our website/app,
            you agree to be bound by these Terms of Service.
          </p>
        </li>
        <li className="mt-6">
          <strong>Service Fees</strong>
          <ol className="list-alpha ms-10 mt-6">
            <li>The service is available at a cost of $200 per year.</li>
            <li>Payment is due annually in advance and is non-refundable.</li>
            <li>
              Failure to pay the annual fee may result in suspension or
              termination of your account.
            </li>
          </ol>
        </li>
        <li className="mt-6">
          <strong>Use of Service</strong>
          <ol className="list-alpha ms-10 mt-6">
            <li>
              The service is intended for users to make phone calls to each
              other. To accomplish this we send text messages and emails on your
              behalf and collect personal information.
            </li>
            <li>Users must be 18 years or older to use the service.</li>
            <li>
              The service is not available to residents of Europe or individuals
              who consider themselves citizens of California, as we do not
              comply with the General Data Protection Regulation (GDPR) or the
              California Consumer Privacy Act (CCPA).
            </li>
          </ol>
        </li>
        <li className="mt-6">
          <strong>User Obligations</strong>
          <ol className="list-alpha ms-10 mt-6">
            <li>
              Users must provide accurate and complete information when creating
              an account.
            </li>
            <li>
              Users are responsible for maintaining the confidentiality of their
              account information.
            </li>
            <li>
              Users agree not to use the service for any unlawful purposes.
            </li>
          </ol>
        </li>
        <li className="mt-6">
          <strong>Termination and Cancellation</strong>
          <ol className="list-alpha ms-10 mt-6">
            <li>
              We reserve the right to terminate or restrict your access to the
              service for any or no reason, without notice, liability, or
              refund.
            </li>
            <li>
              If your account is terminated, you will not receive a refund of
              any fees paid.
            </li>
          </ol>
        </li>
        <li className="mt-6">
          <strong>Prohibited Activities</strong>
          <ol className="list-alpha ms-10 mt-6">
            <li>
              You cannot use Call List for telemarketing or any outbound
              commercial activity.
            </li>
            <li>Don’t do anything else shitty with this.</li>
          </ol>
        </li>
      </ol>
    </div>
  )
}
