import { Formik, Form } from "formik"
import toast from "react-hot-toast"
import * as Yup from "yup"
import { TextField } from "~/ui/formFields/TextField"
import { Button } from "~/ui/Button"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"

type CommunicationTemplatesValues = {
  pendingEmailTemplate?: string
  pendingNoteTemplate?: string
  pendingTextTemplate?: string
  pendingActivationEmailTemplate?: string
}

const validationSchema = Yup.object({
  pendingEmailTemplate: Yup.string()
    .required("Email template is required")
    .test({
      name: "emailVariable",
      message: "Email must contain {{ user_name }} & {{ user_link }}",
      test: (value) =>
        !!value.match(/\{\{ user_link \}\}/) &&
        !!value.match(/\{\{ user_name \}\}/),
    }),
  pendingNoteTemplate: Yup.string()
    .required("Note template is required")
    .test({
      name: "noteVariable",
      message: "Note must contain {{ user_link }}",
      test: (value) => !!value.match(/\{\{ user_link \}\}/),
    }),
  pendingTextTemplate: Yup.string()
    .required("Text template is required")
    .test({
      name: "textVariable",
      message: "Text must contain {{ user_name }}",
      test: (value) => !!value.match(/\{\{ user_name \}\}/),
    }),
  pendingActivationEmailTemplate: Yup.string()
    .required("Activation email template is required")
    .test({
      name: "activationEmailVariable",
      message: "Text must contain {{ user_name }} & {{ contact_name }}",
      test: (value) =>
        !!value.match(/\{\{ user_name \}\}/) &&
        !!value.match(/\{\{ contact_name \}\}/),
    }),
})

export const CommunicationTemplatesForm = () => {
  const currentUser = useCurrentUser()

  const [updateCurrentUserTemplates, { loading }] = useSafeMutation(
    UPDATE_CURRENT_USER_TEMPLATES_MUTATION
  )

  const initialValues: CommunicationTemplatesValues = {
    pendingNoteTemplate:
      currentUser.pendingNoteTemplate || currentUser.noteTemplate,
    pendingEmailTemplate:
      currentUser.pendingEmailTemplate || currentUser.emailTemplate,
    pendingTextTemplate:
      currentUser.pendingTextTemplate || currentUser.textTemplate,
    pendingActivationEmailTemplate:
      currentUser.pendingActivationEmailTemplate ||
      currentUser.activationEmailTemplate,
  }

  const onSubmit = async (values: CommunicationTemplatesValues) => {
    // We need to reset any values that identical to the active templates
    const sanitizedValues = { ...values }

    if (sanitizedValues.pendingEmailTemplate === currentUser.emailTemplate) {
      sanitizedValues.pendingEmailTemplate = ""
    }

    if (sanitizedValues.pendingNoteTemplate === currentUser.noteTemplate) {
      sanitizedValues.pendingNoteTemplate = ""
    }

    if (sanitizedValues.pendingTextTemplate === currentUser.textTemplate) {
      sanitizedValues.pendingTextTemplate = ""
    }

    if (
      sanitizedValues.pendingActivationEmailTemplate ===
      currentUser.activationEmailTemplate
    ) {
      sanitizedValues.pendingActivationEmailTemplate = ""
    }

    const { errors } = await updateCurrentUserTemplates({
      variables: {
        input: {
          ...sanitizedValues,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      toast.success("Templates saved (pending approval)")
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <div className="md:flex gap-8">
            <TextField
              as="textarea"
              name="pendingNoteTemplate"
              label={`Your note ${
                currentUser.pendingNoteTemplate ? "(pending approval)" : ""
              }`}
              containerClassName="md:w-1/2"
            />
            <p className="md:w-1/2 md:mt-6 mt-2 text-sm md:text-base">
              The note you can copy-paste from the call dashboard to invite
              people to add themselves to your call list. This must include{" "}
              <span className="bg-green-200/40 rounded px-1 inline-block">{`{{ user_link }}`}</span>
            </p>
          </div>

          <div className="md:flex gap-8 mt-8">
            <TextField
              as="textarea"
              name="pendingEmailTemplate"
              label={`Availability email ${
                currentUser.pendingEmailTemplate ? "(pending approval)" : ""
              }`}
              containerClassName="md:w-1/2"
            />
            <p className="md:w-1/2 mt-2 md:mt-6 text-sm md:text-base">
              The email you send when requesting contact details from a new
              contact. This must include both{" "}
              <span className="bg-green-200/40 rounded px-1 inline-block">{`{{ user_name }}`}</span>{" "}
              &amp;{" "}
              <span className="bg-green-200/40 rounded px-1 inline-block">{`{{ user_link }}`}</span>
            </p>
          </div>

          <div className="md:flex gap-8 mt-8">
            <TextField
              as="textarea"
              name="pendingActivationEmailTemplate"
              label={`Activation email ${
                currentUser.pendingActivationEmailTemplate
                  ? "(pending approval)"
                  : ""
              }`}
              containerClassName="md:w-1/2"
            />
            <p className="md:w-1/2 md:mt-6 mt-2 text-sm md:text-base">
              Optional email you can send to a pending contact that you just
              added to your list. This must include both{" "}
              <span className="bg-green-200/40 rounded px-1 inline-block">{`{{ contact_name }}`}</span>{" "}
              &amp;{" "}
              <span className="bg-green-200/40 rounded px-1 inline-block">{`{{ user_name }}`}</span>
            </p>
          </div>

          <div className="md:flex gap-8 mt-8">
            <TextField
              as="textarea"
              name="pendingTextTemplate"
              label={`“Free to talk” text ${
                currentUser.pendingTextTemplate ? "(pending approval)" : ""
              }`}
              containerClassName="md:w-1/2"
            />
            <p className="md:w-1/2 md:mt-6 mt-2 text-sm md:text-base">
              The custom note you can copy-paste from the call dashboard to
              invite people to add themselves to your call list. This must
              include{" "}
              <span className="bg-green-200/40 rounded px-1 inline-block">{`{{ user_name }}`}</span>
            </p>
          </div>
          <div className="md:w-1/2 mt-8 pr-4">
            <Button
              label="Submit for approval"
              type="submit"
              className="w-full"
              disabled={loading}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

const UPDATE_CURRENT_USER_TEMPLATES_MUTATION = gql(`
  mutation UpdateCurrentUserTemplates($input: UpdateCurrentUserInput!) {
    updateCurrentUser(input: $input) {
      currentUser {
        id
        pendingEmailTemplate
        pendingNoteTemplate
        pendingTextTemplate
        pendingActivationEmailTemplate
      }
    }
  }
`)
