import { DocumentNode } from "graphql"
import { Formik, Form } from "formik"
import toast from "react-hot-toast"
import { Duration, add } from "date-fns"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useArchiveContact } from "~/common/useArchiveContact"
import { displayErrors } from "~/common/validations"
import { TextField } from "../ui/formFields/TextField"
import { RadioButtonGroup } from "../ui/formFields/RadioButtonGroup"
import { Button } from "~/ui/Button"
import { Contact } from "~/__generated__/graphql"

interface ArchiveContactFormProps {
  contact: Contact
  refetchQueries: DocumentNode[]
  onCancel: () => void
}

export const ArchiveContactForm = ({
  contact,
  refetchQueries,
  onCancel,
}: ArchiveContactFormProps) => {
  const [setRecurrencAndMaybeSendAnEmail, { loading }] =
    useSafeMutation(SET_RECURRENCE)
  const { archiveContact, loading: archivingInProgress } = useArchiveContact({
    refetchQueries,
  })

  const onSubmit = async (values: any) => {
    archiveContact(contact.id)

    let recurAt = null
    if (values.recurAt !== "never") {
      recurAt = add(new Date(), JSON.parse(values.recurAt)).toISOString()
    }

    const { errors } = await setRecurrencAndMaybeSendAnEmail({
      variables: {
        input: {
          contactId: contact.id,
          recurAt,
          emailBody: values.emailBody,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      if (values.emailBody)
        toast.success(`Email sent to ${contact.currentEmail}`)
      displayRecurrenceToast(
        recurAt ? JSON.parse(values.recurAt) : values.recurAt
      )
    }
  }

  const initialValues = {
    emailBody: "",
    recurAt: "never",
  }

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {() => (
          <Form className="flex-1">
            <TextField
              name="emailBody"
              className="w-full mb-6"
              labelClassName="mt-4"
              label="Send them an email (optional)"
              as="textarea"
              minRows={6}
            />
            <RadioButtonGroup
              options={recurrenceOptions}
              label="Add follow up to your call list in"
              name="recurAt"
              layout="vertical"
            />
            <div className="w-full flex gap-2 md:w-[unset] mt-6">
              <Button
                label="Archive"
                size="sm"
                type="submit"
                disabled={loading || archivingInProgress}
              />
              <Button
                label="Cancel"
                size="sm"
                color="secondary"
                disabled={loading}
                onClick={onCancel}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const recurrenceOptions = [
  {
    label: "1 week",
    value: JSON.stringify({ weeks: 1 }),
  },
  {
    label: "1 month",
    value: JSON.stringify({ months: 1 }),
  },
  {
    label: "1 quarter",
    value: JSON.stringify({ months: 3 }),
  },
  {
    label: "1 year",
    value: JSON.stringify({ years: 1 }),
  },
  {
    label: "Never",
    value: "never",
  },
]

const pluralize = (count: number, singular: string, plural: string) => {
  if (count === 1) {
    return singular
  } else {
    return plural
  }
}

const displayRecurrenceToast = (recurIn: Duration | "never") => {
  let message = null

  if (recurIn === "never") {
    message = "Contact archived."
  } else if (recurIn.years) {
    message = `Contact will recur in ${recurIn.years} ${pluralize(
      recurIn.years,
      "year",
      "years"
    )}.`
  } else if (recurIn.months) {
    message = `Contact will recur in ${recurIn.months} ${pluralize(
      recurIn.months,
      "month",
      "months"
    )}.`
  } else if (recurIn.weeks) {
    message = `Contact will recur in ${recurIn.weeks} ${pluralize(
      recurIn.weeks,
      "week",
      "weeks"
    )}.`
  } else if (recurIn.days) {
    message = `Contact will recur in ${recurIn.years} ${pluralize(
      recurIn.days,
      "day",
      "days"
    )}.`
  }

  if (message) {
    toast.success(message)
  }
}

const SET_RECURRENCE = gql(`
  mutation SetRecurrence($input: UpdateContactInput!) {
    updateContact(input: $input) {
      contact {
        id
        recurAt
      }
    }
  }
`)
