import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { landingPath } from "~/common/paths"
import { AvailabilityForm } from "~/contacts/AvailabilityForm"
import { Error } from "~/ui/Error"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"

export const AvailabilityFormScreen = () => {
  const [isComplete, setIsComplete] = useState(false)
  const { pathname } = useLocation()
  const formPath = pathname.split("/").filter((v) => v)[0]
  const { data, loading, error } = useQuery(USER_FOR_FORM_QUERY, {
    variables: { formPath },
  })
  const navigate = useNavigate()

  useEffect(() => {
    if (!loading && data && data.userForAvailabilityForm === null) {
      navigate("/not-found")
    }
  }, [data, loading, navigate])

  if (loading) return <LoadingIndicatorCentered />
  if (error) return <Error message="Error loading form." />

  invariant(data)
  if (!data.userForAvailabilityForm) return null

  return (
    <div className="max-w-2xl mx-auto p-3">
      {isComplete ? (
        <div>
          Wonderful.
          <br />
          <br />I passed all this information to{" "}
          {data.userForAvailabilityForm.name} and they will be in contact soon!
          <br />
          <br />
          <Link to={landingPath.pattern}>Learn more about Call List</Link>
        </div>
      ) : (
        <>
          <div className="mb-8">
            {data.userForAvailabilityForm.name} would be happy to connect.
          </div>
          <AvailabilityForm
            callListUser={data.userForAvailabilityForm}
            onComplete={() => setIsComplete(true)}
          />
        </>
      )}
    </div>
  )
}

export const USER_FOR_FORM_QUERY = gql(`
  query UserForFormQuery($formPath: String!) {
    userForAvailabilityForm(formPath: $formPath) {
      id
      name
      requireCallNotes
    }
  }
`)
