import { useEffect, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { SubscriptionForm } from "~/subscriptions/SubscriptionForm"
import { LoadingIndicator } from "~/ui/LoadingIndicator"

const INITIALIZE_CHECKOUT_MUTATION = gql(`
  mutation InitializeCheckout($input: InitializeCheckoutInput!) {
    initializeCheckout(input: $input) {
      clientSecret
      afterSubscribeUrl
      formattedPrice
      paymentInterval
    }
  }
`)

export const NewSubscriptionScreen = () => {
  const initializedRef = useRef(false)
  const [searchParams] = useSearchParams()

  const offer = searchParams.get("offer")

  const [initializeCheckout, { data, error, loading }] = useSafeMutation(
    INITIALIZE_CHECKOUT_MUTATION
  )

  useEffect(() => {
    if (initializedRef.current) return

    initializedRef.current = true
    initializeCheckout({
      variables: {
        input: {
          offer,
        },
      },
    })
  }, [initializeCheckout, offer])

  return (
    <div className="max-w-md m-auto">
      <h1 className="mb-7">Subscribe</h1>

      {loading && <LoadingIndicator />}

      {data && (
        <SubscriptionForm
          clientSecret={data.initializeCheckout.clientSecret}
          redirectUrl={data.initializeCheckout.afterSubscribeUrl}
          formattedPrice={data.initializeCheckout.formattedPrice}
          paymentInterval={data.initializeCheckout.paymentInterval}
        />
      )}

      {error && (
        <div>
          Error initializing checkout. Please reload the page and try again.
        </div>
      )}
    </div>
  )
}
