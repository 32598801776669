import { DocumentNode } from "graphql"
import { toast } from "react-hot-toast"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"

interface UseDeleteContactProps {
  refetchQueries?: DocumentNode[]
}

export const useDeleteContact = ({ refetchQueries }: UseDeleteContactProps) => {
  const [mutate, { loading }] = useSafeMutation(DELETE_CONTACT_MUTATION, {
    refetchQueries,
  })

  const deleteContact = async (id: string) => {
    const { errors } = await mutate({
      variables: {
        input: {
          id,
        },
      },
    })

    if (errors) {
      console.error(errors)
      toast.error("Deleting failed, please try again")
    }
  }

  return { deleteContact, loading }
}

const DELETE_CONTACT_MUTATION = gql(`
  mutation DeleteContact($input: DeleteContactInput!) {
    deleteContact(input: $input) {
      success
    }
  }
`)
