import { Formik, Form, FormikHelpers } from "formik"
import toast from "react-hot-toast"
import * as Yup from "yup"
import { TextField } from "~/ui/formFields/TextField"
import { Button } from "~/ui/Button"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { CheckBoxField } from "~/ui/formFields/CheckBoxField"
import { CommunicationTemplatesForm } from "~/settings/CommunicationTemplatesForm"

type UserHandleValues = {
  inboundEmail: string
  formPath: string
  dailyCallListEnabled: boolean
  emailAvailabilityFormEnabled: boolean
  requireCallNotes: boolean
}

export const SettingsScreen = () => {
  const currentUser = useCurrentUser()
  const [updateCurrentUserHandles, { loading }] = useSafeMutation(
    UPDATE_CURRENT_USER_HANDLES_MUTATION
  )

  const validationSchemaForHandles = Yup.object({
    inboundEmail: Yup.string().required("Email handle is required"),
    formPath: Yup.string().required("Link handle is required"),
  })

  const onSubmit = async (
    values: UserHandleValues,
    { setFieldError }: FormikHelpers<UserHandleValues>
  ) => {
    const { errors } = await updateCurrentUserHandles({
      variables: {
        input: {
          ...values,
        },
      },
    })

    if (errors) {
      displayErrors(errors, setFieldError)
    } else {
      toast.success("Settings updated")
    }
  }

  const initialValues: UserHandleValues = {
    inboundEmail: currentUser.inboundEmail ?? "",
    formPath: currentUser.formPath ?? "",
    dailyCallListEnabled: currentUser.dailyCallListEnabled,
    emailAvailabilityFormEnabled: currentUser.emailAvailabilityFormEnabled,
    requireCallNotes: currentUser.requireCallNotes,
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaForHandles}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <div className="md:flex gap-8">
              <div className="md:w-1/2">
                <TextField name="formPath" label="Link handle" required />
                <div className="bg-green-200/40 rounded mt-2 px-1 -ms-1 inline-block">
                  {currentUser.callListUrl}
                </div>
              </div>
              <p className="md:w-1/2 md:mt-6 mt-2 text-sm md:text-base">
                Send this link to people to have them add their info to your
                call list
              </p>
            </div>
            <div className="md:flex gap-8 mt-8">
              <div className="md:w-1/2">
                <TextField name="inboundEmail" label="Email handle" required />
                <div className="bg-green-200/40 rounded mt-2 px-1 -ms-1 inline-block">
                  {currentUser.callListEmail}
                </div>
              </div>
              <p className="md:w-1/2 md:mt-6 mt-2 text-sm md:text-base">
                Give people this email address. When they message in, they will
                get an auto-reply to add themselves to your call list (you will
                be CCed on the reply so you see their message)
              </p>
            </div>
            <div className="mt-8 md:w-1/2">
              <CheckBoxField
                name="dailyCallListEnabled"
                label="Receive a daily email with your current call list."
              />
              <CheckBoxField
                name="emailAvailabilityFormEnabled"
                label="Receive an email after someone fills out your Call List availability form."
                className="mt-4"
              />
              <CheckBoxField
                name="requireCallNotes"
                label="Require notes when a contact fills out your form."
                className="mt-4"
              />
            </div>
            <div className="md:w-1/2 mt-8 pr-4">
              <Button
                label="Save"
                type="submit"
                className="w-full"
                disabled={loading}
              />
            </div>
          </Form>
        )}
      </Formik>
      <hr className="my-8" />
      <h2 className="mb-8">Communication templates</h2>
      <CommunicationTemplatesForm />
    </div>
  )
}

const UPDATE_CURRENT_USER_HANDLES_MUTATION = gql(`
  mutation UpdateCurrentUserHandles($input: UpdateCurrentUserInput!) {
    updateCurrentUser(input: $input) {
      currentUser {
        id
        inboundEmail
        callListEmail
        callListUrl
        dailyCallListEnabled
        emailAvailabilityFormEnabled
        requireCallNotes
      }
    }
  }
`)
