import { DocumentNode } from "graphql"
import { toast } from "react-hot-toast"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"

interface UseArchiveContactProps {
  refetchQueries?: DocumentNode[]
}

export const useArchiveContact = ({
  refetchQueries,
}: UseArchiveContactProps) => {
  const [mutate, { loading }] = useSafeMutation(ARCHIVE_CONTACT_MUTATION, {
    refetchQueries,
  })

  const archiveContact = async (id: string) => {
    const { errors } = await mutate({
      variables: {
        input: {
          id,
        },
      },
    })

    if (errors) {
      console.error(errors)
      toast.error("Archiving failed, please try again")
    }
  }

  return { archiveContact, loading }
}

const ARCHIVE_CONTACT_MUTATION = gql(`
  mutation ArchiveContact($input: ArchiveContactInput!) {
    archiveContact(input: $input) {
      contact {
        id
        name
        state
        contactCallNotes
        currentEmail
        currentPhone
        notes
      }
    }
  }
`)
