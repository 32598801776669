import { Link } from "react-router-dom"
import { Formik, Form, FormikHelpers } from "formik"
import * as Yup from "yup"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { termsPath } from "~/common/paths"
import { TextField } from "~/ui/formFields/TextField"
import { Button } from "~/ui/Button"

const CREATE_USER_MUTATION = gql(`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        email
      }
    }
  }
`)

type SignUpValues = {
  name?: string
  email: string
  phone?: string
}

export const SignUpScreen = () => {
  const [createUser, { data, loading }] = useSafeMutation(CREATE_USER_MUTATION)

  const initialValues: SignUpValues = {
    name: "",
    email: "",
    phone: "",
  }

  const validationSchema = Yup.object({
    name: Yup.string(),
    email: Yup.string().email().required(),
  })

  const onSubmit = async (
    values: SignUpValues,
    { setFieldError }: FormikHelpers<SignUpValues>
  ) => {
    const { errors } = await createUser({
      variables: {
        input: {
          ...values,
        },
      },
    })

    if (errors) {
      displayErrors(errors, setFieldError)
    }
  }

  return (
    <div className="max-w-md m-auto">
      <h1>Sign up</h1>
      {data?.createUser.user ? (
        <p className="my-8">
          Your account was created, please check your email at{" "}
          <strong>{data?.createUser.user.email}</strong> to sign in and
          get&nbsp;started.
        </p>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {() => (
            <Form className="my-8">
              <TextField name="name" placeholder="Your full name" />
              <TextField
                name="email"
                placeholder="Your email"
                labelClassName="mt-4"
              />
              <TextField
                type="tel"
                name="phone"
                placeholder="e.g. 555-555-5555"
                labelClassName="mt-4"
              />
              <TextField
                name="referralCode"
                label="Referral code"
                labelClassName="mt-4"
              />

              <p className="mt-8">
                By creating an account, you are agreeing to our{" "}
                <Link to={termsPath.pattern}>terms and&nbsp;conditions</Link>.
              </p>

              <Button
                className="btn mt-8 w-full"
                disabled={loading}
                label="Create account"
                type="submit"
              />
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}
