import React from "react"
import clsx from "clsx"

type FieldsetProps = {
  legend?: string
  className?: string
  children: React.ReactNode
}

export const Fieldset = ({ legend, children, className }: FieldsetProps) => {
  return (
    <fieldset className={clsx("border-2 dark:border-gray-600 p-4", className)}>
      {legend && <legend className="font-bold px-2 -ml-2">{legend}</legend>}
      <div>{children}</div>
    </fieldset>
  )
}
