import { useState } from "react"
import { Link } from "react-router-dom"
import toast from "react-hot-toast"
import clsx from "clsx"
import { useCurrentUser } from "~/auth/CurrentUserContext"

export const UserTools = () => {
  const currentUser = useCurrentUser()
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="backdrop-blur-lg bg-green-200/40 rounded px-4 md:px-6 py-2 md:py-4">
      <div
        className={clsx(
          "w-full shrink-0 justify-between flex",
          expanded && "border-b border-black dark:border-white mb-4 pb-4"
        )}
      >
        <strong>Your links</strong>{" "}
        <button
          type="button"
          className="hover:text-pink-600 text-underline"
          onClick={() => setExpanded((prev) => !prev)}
        >
          [{expanded ? "hide" : "view"}]
        </button>
      </div>
      <div
        className={clsx(
          "md:flex gap-4 overflow-hidden",
          expanded && "h-auto",
          !expanded && "h-0"
        )}
      >
        <div className="md:w-2/6 md:min-w-64 shrink-0 md:border-r md:border-black md:pr-4">
          <p>To add someone to your list of folks to call you can:</p>
          <p>
            Give them your URL to add their details, or tell them to email your
            address here.{" "}
            <Link to="https://docs.calllist.app/">Learn&nbsp;more</Link>
          </p>
        </div>
        <div className="mt-4 md:mt-0">
          {currentUser.callListUrl && (
            <UserLink label="URL" link={currentUser.callListUrl} />
          )}
          {currentUser.callListEmail && (
            <UserLink
              label="Email"
              link={currentUser.callListEmail}
              className="mt-4"
            />
          )}
          <UserLink label="Note" link={currentUser.noteCopy} className="mt-4" />
        </div>
      </div>
    </div>
  )
}

interface UserLinkProps {
  className?: string
  label: string
  link: string
}

const UserLink = ({ link, label, className }: UserLinkProps) => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    toast.success("copied to clipboard")
  }

  return (
    <div
      className={clsx("flex gap-x-4 flex-wrap md:items-baseline", className)}
    >
      <strong className="w-full md:w-24 block md:text-right md:items-baseline">
        {label}:
      </strong>
      <p
        className="flex-1 bg-white dark:bg-black min-w-full md:min-w-0 px-2 -ml-2 break-words"
        dangerouslySetInnerHTML={{ __html: link }}
      />
      <button
        type="button"
        className="hover:text-pink-600 underline"
        onClick={() => copyToClipboard(link)}
      >
        [copy]
      </button>
    </div>
  )
}
