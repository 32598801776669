import React, { useState } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { getStripeKey } from "~/util/getStripeKey"
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { Button } from "~/ui/Button"
import toast from "react-hot-toast"
import { PaymentIntervalEnum } from "~/__generated__/graphql"

const stripePromise = loadStripe(getStripeKey())

export const SubscriptionForm = (props: {
  clientSecret: string
  redirectUrl: string
  formattedPrice: string
  paymentInterval: PaymentIntervalEnum
}) => {
  const { clientSecret, ...rest } = props

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: {
          variables: {
            fontFamily:
              'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
            borderRadius: "0",
            fontSizeBase: "16px",
            fontSizeSm: "16px",
            spacingGridRow: "24px",
          },
          rules: {
            ".Input": {
              borderColor: "#000",
              borderWidth: "2px",
            },
          },
        },
      }}
    >
      <SubscriptionFormInner {...rest} />
    </Elements>
  )
}

export const SubscriptionFormInner = ({
  redirectUrl,
  formattedPrice,
  paymentInterval,
}: {
  redirectUrl: string
  formattedPrice: string
  paymentInterval: PaymentIntervalEnum
}) => {
  const [formReady, setFormReady] = useState(false)
  const [submitPending, setSubmitPending] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    if (submitPending) return

    setSubmitPending(true)

    if (!stripe || !elements) {
      setSubmitPending(false)
      return
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: redirectUrl,
      },
    })

    if (result.error) {
      if (result.error.message) {
        toast.error(result.error.message)
      }
      setSubmitPending(false)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-7">
        The subscription is {formattedPrice}/
        {paymentInterval.toLocaleLowerCase()}. Your subscription will be
        processed and managed by{" "}
        <a rel="noreferrer" target="_blank" href="https://stripe.com">
          Stripe
        </a>
        .
      </div>
      <PaymentElement onReady={() => setFormReady(true)} />
      {formReady && (
        <Button
          className="btn mt-8 w-full"
          label="Subscribe"
          type="submit"
          disabled={submitPending}
        />
      )}
    </form>
  )
}
