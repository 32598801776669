import { Link } from "react-router-dom"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"
import { accountSettingsPath } from "~/common/paths"

export const UpdateProfileBanner = () => {
  const {
    currentUser,
    result: { loading },
  } = useCurrentUserMaybe()

  if (loading || !currentUser || (currentUser.phone && currentUser.name))
    return null

  return (
    <div className="bg-pink-600 text-white py-2 px-4 text-center">
      {!currentUser.phone &&
        !currentUser.name &&
        "You need a phone number to text, and your name so you are not creepy."}
      {!currentUser.phone &&
        currentUser.name &&
        "You need a phone number to text."}
      {currentUser.phone &&
        !currentUser.name &&
        "Don’t be a creep, add your name."}{" "}
      <Link
        className="text-white hover:opacity-80 hover:text-white"
        to={accountSettingsPath.pattern}
      >
        Update&nbsp;your&nbsp;profile&nbsp;now&nbsp;&rarr;
      </Link>
    </div>
  )
}
