import React from "react"
import clsx from "clsx"

interface AdminTableCellProps {
  children: React.ReactNode
  heading?: boolean
  className?: string
  hideOnMobile?: boolean
}

export const AdminTableCell = ({
  className,
  children,
  heading,
  hideOnMobile,
}: AdminTableCellProps) => {
  return (
    <td
      className={clsx(
        "border border-black p-2 dark:border-white",
        className,
        heading && "font-bold bg-gray-200 dark:bg-gray-700 align-top",
        hideOnMobile && "hidden sm:table-cell"
      )}
    >
      {children}
    </td>
  )
}
