import { useParams } from "react-router-dom"
import { FormikHelpers } from "formik"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { displayErrors } from "~/common/validations"
import { Breadcrumb } from "~/ui/Breadcrumb"
import { adminUsersIndexPath } from "~/common/paths"
import { AdminUserForm, UserFormFields } from "~/admin/AdminUserForm"
import { AdminUserTemplatesForm } from "~/admin/AdminUserTemplatesForm"
import { useQuery } from "@apollo/client"
import { useSafeMutation } from "~/common/useSafeMutation"
import invariant from "tiny-invariant"

export const AdminUserUpdateScreen = () => {
  const { userId } = useParams()
  const userQuery = useQuery(ADMIN_USER_ROUTE_DOCUMENT, {
    variables: { userId: userId! },
  })
  const [userMutation] = useSafeMutation(UPDATE_USER_MUTATION)

  if (userQuery.error) return <div>error loading user</div>
  if (userQuery.loading) return <div>loading …</div>
  invariant(userQuery.data)

  const { adminUser: user } = userQuery.data

  const pendingTemplateApprovals =
    user.pendingEmailTemplate ||
    user.pendingNoteTemplate ||
    user.pendingTextTemplate ||
    user.pendingActivationEmailTemplate

  const initialValues: UserFormFields = {
    email: user.email ?? "",
    name: user.name ?? "",
    phone: user.phone ?? "",
    isAdmin: user.isAdmin ?? false,
    hasCompAccess: user.hasCompAccess ?? false,
    enrichment: user.enrichment,
    referralCode: user.referralCode ?? "",
    overrideStripePriceId: user.overrideStripePriceId ?? "",
  }

  const onSubmit = async (
    values: UserFormFields,
    { setSubmitting, setFieldError }: FormikHelpers<UserFormFields>
  ) => {
    const { errors } = await userMutation({
      variables: {
        input: {
          id: userId!,
          userInput: values,
        },
      },
    })

    if (errors) {
      displayErrors(errors, setFieldError)
    } else {
      toast.success("User updated")
    }

    setSubmitting(false)
  }

  return (
    <div className="max-w-3xl m-auto">
      <Breadcrumb
        path={adminUsersIndexPath.pattern}
        label="Users"
        className="mb-8"
      />
      <>
        <div className="mb-8">
          <h1>Editing {user.name}</h1>
          <small>
            {user.id}{" "}
            {user.referralCode && (
              <>
                &middot; Referral code:{" "}
                <span className="bg-green-200/40 rounded px-1 inline-block">
                  {user.referralCode}
                </span>
              </>
            )}
          </small>
        </div>
        {pendingTemplateApprovals && <AdminUserTemplatesForm user={user} />}
        <AdminUserForm initialValues={initialValues} onSubmit={onSubmit} />
      </>
    </div>
  )
}

export const ADMIN_USER_ROUTE_DOCUMENT = gql(`
  query AdminUserRoute($userId: ID!) {
    adminUser(userId: $userId) {
      id
      name
      email
      phone
      isAdmin
      hasCompAccess
      enrichment
      referralCode
      overrideStripePriceId
      pendingEmailTemplate
      pendingNoteTemplate
      pendingTextTemplate
      pendingActivationEmailTemplate
    }
  }
`)

const UPDATE_USER_MUTATION = gql(`
  mutation AdminUpdateUserMutation($input: UserUpdateInput!) {
    adminUserUpdate(input: $input) {
      user {
        id
        email
        name
        phone
        isAdmin
        hasCompAccess
        enrichment
        referralCode
        overrideStripePriceId
      }
    }
  }
`)
