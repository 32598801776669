import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { adminUserCreatePath, adminUserUpdatePath } from "~/common/paths"
import { AdminTableCell } from "~/ui/AdminTabelCell"
import invariant from "tiny-invariant"
import { Link } from "react-router-dom"

export const AdminUsersScreen = () => {
  const result = useQuery(ADMIN_USERS_QUERY_DOCUMENT)

  if (result.error) return <div>error</div>
  if (result.loading) return <div>loading …</div>
  invariant(result.data)

  if (result.data?.adminUsers.nodes.length === 0)
    return (
      <div className="text-2xl text-center">
        We do not have any users yet :(
      </div>
    )

  return (
    <>
      <div className="sm:flex items-baseline justify-between">
        <h1>Users</h1>
        <a className="inline-block" href={adminUserCreatePath.pattern}>
          + Add new user
        </a>
      </div>
      <div className="overflow-x-auto mt-8">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <AdminTableCell heading>Name</AdminTableCell>
              <AdminTableCell heading hideOnMobile>
                Email
              </AdminTableCell>
              <AdminTableCell heading hideOnMobile>
                Subscription status
              </AdminTableCell>
              <AdminTableCell heading>Needs moderation?</AdminTableCell>
              <AdminTableCell heading hideOnMobile>
                Comped?
              </AdminTableCell>
              <AdminTableCell heading hideOnMobile>
                Enrichment
              </AdminTableCell>
              <AdminTableCell heading hideOnMobile>
                Contacts
              </AdminTableCell>
              <AdminTableCell heading>Actions</AdminTableCell>
            </tr>
          </thead>
          <tbody>
            {result.data.adminUsers.nodes.map((u) => {
              return (
                <tr key={u.id}>
                  <AdminTableCell>
                    {u.name}{" "}
                    {u.isAdmin && (
                      <small className="text-neutral-500">[admin]</small>
                    )}
                  </AdminTableCell>
                  <AdminTableCell hideOnMobile>{u.email}</AdminTableCell>
                  <AdminTableCell hideOnMobile>
                    {u.subscriptionStatus}
                  </AdminTableCell>
                  <AdminTableCell>
                    {u.hasPendingTemplateUpdates && "Yes"}
                  </AdminTableCell>
                  <AdminTableCell hideOnMobile>
                    {u.hasCompAccess && "Yes"}
                  </AdminTableCell>
                  <AdminTableCell hideOnMobile>{u.enrichment}</AdminTableCell>
                  <AdminTableCell hideOnMobile>
                    {u.contactsCount?.toLocaleString()}
                  </AdminTableCell>
                  <AdminTableCell>
                    <Link to={adminUserUpdatePath({ userId: u.id })}>edit</Link>
                  </AdminTableCell>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

const ADMIN_USERS_QUERY_DOCUMENT = gql(`
  query AdminUsersRoute {
    adminUsers {
      nodes {
        id
        name
        email
        isAdmin
        inboundEmail
        createdAt
        subscriptionStatus
        hasCompAccess
        hasPendingTemplateUpdates
        contactsCount
        enrichment
      }
    }
  }
`)
