import { Formik, Form, useField } from "formik"
import * as Yup from "yup"
import { useEffect, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import {
  UserForFormQueryQuery,
  ContactResponsePreferenceEnum,
} from "~/__generated__/graphql"
import { TextField } from "~/ui/formFields/TextField"
import { RadioButtonGroup } from "~/ui/formFields/RadioButtonGroup"
import { Button } from "~/ui/Button"
import DropdownField from "~/ui/formFields/DropdownField"
import {
  SUPPORTED_TIMEZONES,
  TIMEZONE_SELECT_OPTIONS,
} from "~/common/timezones"
import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "~/__generated__"
import invariant from "tiny-invariant"
import toast from "react-hot-toast"

type ContactValues = {
  name: string
  email: string
  phone: string
  availability: string
  timezone: string
  contactCallNotes: string
  contactUrgency: string
  source: string | null
  responsePreference: ContactResponsePreferenceEnum
}

export const AvailabilityForm = ({
  callListUser,
  onComplete,
}: {
  callListUser: UserForFormQueryQuery["userForAvailabilityForm"]
  onComplete: () => void
}) => {
  invariant(callListUser)

  const [source] = useSearchParams()

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone is required"),
    email: Yup.string().required("Email is required"),
    contactCallNotes: callListUser.requireCallNotes
      ? Yup.string().required("Notes are required")
      : Yup.string().notRequired(),
  })

  const [addAvailability, { loading }] = useSafeMutation(ADD_AVAILABILITY)
  const initialValues: ContactValues = {
    name: "",
    email: "",
    phone: "",
    availability: "",
    timezone: "",
    contactCallNotes: "",
    contactUrgency: "",
    source: source.get("source"),
    responsePreference: ContactResponsePreferenceEnum.Call,
  }

  const onSubmit = async (values: ContactValues) => {
    const { data, errors } = await addAvailability({
      variables: {
        input: {
          userId: callListUser.id,
          ...values,
        },
      },
    })

    if (data?.addAvailability.contact.id) {
      onComplete()
    } else {
      toast.error("Error updating contact")
      console.log(errors)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {() => (
        <Form>
          <TimezoneDefaultSetter />
          <TextField
            name="name"
            label="Your Name"
            required
            className="mb-2"
            containerClassName="mb-6"
          />
          <TextField
            name="phone"
            label="Your Cell"
            required
            className="mb-2"
            containerClassName="mb-6"
          />
          <TextField
            name="email"
            label="Your Email"
            required
            className="mb-2"
            containerClassName="mb-6"
          />
          <TextField
            name="contactCallNotes"
            label="Notes"
            containerClassName="mb-6"
            as="textarea"
            minRows={4}
            placeholder="Anything you want to cover?"
            required={callListUser.requireCallNotes}
          />
          <DropdownField
            name="timezone"
            label="Your Time Zone"
            containerClassName="mb-8"
            options={TIMEZONE_SELECT_OPTIONS}
          />
          <TextField
            name="availability"
            containerClassName="mb-6"
            as="textarea"
            minRows={4}
            placeholder="Mornings, evenings, weekends, etc."
          />
          <TextField
            name="contactUrgency"
            label="Urgency"
            containerClassName="mb-6"
            className="mb-2"
            placeholder="Today, next few days, this week, soon"
          />
          <RadioButtonGroup
            options={responsePreferenceOptions}
            label="Response Preference"
            name="responsePreference"
            className="mb-6"
            layout="vertical"
          />
          <div className="flex justify-end">
            <Button
              label="Save"
              className="w-full"
              type="submit"
              disabled={loading}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

const TimezoneDefaultSetter = () => {
  const [, , helpers] = useField("timezone")
  const setOnceRef = useRef(false)

  useEffect(() => {
    if (setOnceRef.current) return

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (SUPPORTED_TIMEZONES.find((tz) => tz[1] === timezone)) {
      setOnceRef.current = true
      helpers.setValue(timezone)
    }
  }, [helpers])

  return null
}

const responsePreferenceOptions = [
  { value: ContactResponsePreferenceEnum.Call, label: "Call" },
  { value: ContactResponsePreferenceEnum.Email, label: "Email" },
  { value: ContactResponsePreferenceEnum.None, label: "Call or Email" },
]

const ADD_AVAILABILITY = gql(`
  mutation AddAvailability($input: AddAvailabilityInput!) {
    addAvailability(input: $input) {
      contact {
        id
      }
    }
  }
`)
