export const AboutScreen = () => {
  return (
    <div className="max-w-3xl mx-auto">
      <h2>About Call List</h2>
      <p className="mt-6">
        Call List is a service which helps executives use their time better.
        Rather than relying on ‘basic’ first-in-first-out calendaring (like
        plebs do), Call List allows people to keep lists of who they want to
        talk to at any given time and prioritize their daily interactions- the
        same way that executives in holywood and finance have ‘rolled calls’ for
        generations with their assistants.
      </p>
      <p>To get the most out of Call List use the following functionality:</p>
      <ol className="list-decimal ms-10 mt-6">
        <li>
          Use call list to keep a prioritized list of who you want to reach out
          to with their email and phone number.
        </li>
        <li className="mt-6">
          Ask in one-click via text if someone is ‘free to talk’ when you are,
          and collect feedback on when they can talk if they aren’t free at that
          exact moment.
        </li>
        <li className="mt-6">
          Ask via email people you want to reach out to to fill in key
          information, including their cell phone number, likely availability to
          take a call from you, and any topics they want to discuss.
        </li>
        <li className="mt-6">
          Use your custom call list email. When you meet someone at a
          conference, give them your call-list email address. When they send you
          a note there, it is forwarded to your inbox (without giving away your
          true email) AND call list responds with a form for them to fill in to
          find time to talk.
        </li>
      </ol>
    </div>
  )
}
