import { useState, useRef, KeyboardEvent, useEffect } from "react"
import { useQuery } from "@apollo/client"
import toast from "react-hot-toast"
import clsx from "clsx"
import { gql } from "~/__generated__"
import { ContactStateEnum } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { ACTIVE_CONTACTS_QUERY } from "~/screens/ActiveContactsScreen"
import { CONTACT_LAYOUT_COUNTS_QUERY } from "~/layouts/ContactsLayout"
import { Button } from "~/ui/Button"
import { displayErrors } from "~/common/validations"
import { useActivateContact } from "~/common/useActivateContact"

const ADD_CONTACT_FORM_CLASS = "add-contact-form"

export const AddContactForm = () => {
  const [addContact, { loading }] = useSafeMutation(ADD_CONTACT_MUTATION, {
    refetchQueries: [ACTIVE_CONTACTS_QUERY],
  })

  const [rawInput, setRawInput] = useState("")
  const [suggestedContactIndex, setSuggestedContactIndex] = useState(-1)
  const inputRef = useRef(null)

  const {
    data,
    loading: searching,
    updateQuery,
  } = useQuery(SEARCH_CONTACTS_QUERY, {
    variables: { query: rawInput },
    skip: rawInput.length === 0,
  })

  const { activateContact } = useActivateContact({
    refetchQueries: [ACTIVE_CONTACTS_QUERY, CONTACT_LAYOUT_COUNTS_QUERY],
  })

  useEffect(() => {
    const onKeyDown: EventListenerOrEventListenerObject = (event) => {
      // @ts-ignore
      if (event.key === "Escape") {
        updateQuery(() => ({ searchContacts: { nodes: [] } }))
      }
    }

    document.addEventListener("keydown", onKeyDown)
    return () => document.removeEventListener("keydown", onKeyDown)
  }, [updateQuery])

  useEffect(() => {
    const onClickOutside: EventListenerOrEventListenerObject = (e) => {
      if (
        // @ts-ignore
        !e.target?.matches(`.${ADD_CONTACT_FORM_CLASS}`) &&
        // @ts-ignore
        !e.target?.closest(`.${ADD_CONTACT_FORM_CLASS}`)
      ) {
        updateQuery(() => ({ searchContacts: { nodes: [] } }))
      }
    }

    window.addEventListener("click", onClickOutside)
    return () => window.removeEventListener("click", onClickOutside)
  }, [updateQuery])

  const suggestedContacts = data?.searchContacts.nodes || []

  const onSubmit = async () => {
    const { data: contactData, errors } = await addContact({
      variables: {
        input: {
          rawInput,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      setRawInput("")
      toast.success("Contact added")
    }

    console.log(contactData)
  }

  const onPickSuggestion = (contactId: string) => {
    activateContact(contactId)
    // Reset input and selected suggestion
    setSuggestedContactIndex(-1)
    setRawInput("")
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault()
      setSuggestedContactIndex((prevIndex) =>
        Math.min(prevIndex + 1, suggestedContacts.length - 1)
      )
    } else if (event.key === "ArrowUp") {
      event.preventDefault()
      setSuggestedContactIndex((prevIndex) => Math.max(prevIndex - 1, 0))
    } else if (event.key === "Enter" && suggestedContactIndex >= 0) {
      event.preventDefault()
      onPickSuggestion(suggestedContacts[suggestedContactIndex].id)
    } else if (event.key === "Escape") {
      // Reset the search results
      updateQuery(() => ({ searchContacts: { nodes: [] } }))
    }
  }

  return (
    <form className={ADD_CONTACT_FORM_CLASS}>
      <label>
        <span className="font-bold">Add contact</span>
        <div className="relative">
          <div className="relative">
            <input
              ref={inputRef}
              type="text"
              value={rawInput}
              onChange={(e) => {
                setRawInput(e.target.value)
                setSuggestedContactIndex(-1)
              }}
              className="w-full border-2 border-black dark:bg-black dark:border-white md:pr-24 md:text-xl"
              placeholder="Name, email, phone -- notes"
              onKeyDown={handleKeyDown}
            />
            {rawInput && !searching && !!data?.searchContacts.nodes.length && (
              <div
                className={clsx(
                  ADD_CONTACT_FORM_CLASS,
                  "absolute w-full top-full left-0 z-30 bg-white dark:bg-black border-2 border-black dark:border-white border-t-0"
                )}
                onKeyDown={handleKeyDown}
              >
                <ol>
                  {data?.searchContacts.nodes.map((contact, index) => (
                    <li
                      key={contact.id}
                      className={clsx(
                        "block p-2 px-3 cursor-pointer hover:bg-gray-500/20 focus:bg-pink-600 focus:text-white",
                        suggestedContactIndex === index &&
                          "!bg-pink-600 !text-white"
                      )}
                      tabIndex={0}
                      onClick={() => onPickSuggestion(contact.id)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault()
                          onPickSuggestion(contact.id)
                        }
                      }}
                    >
                      <div>
                        <strong>{contact.name}</strong>{" "}
                        {contact.state === ContactStateEnum.Pending && (
                          <small>[requested]</small>
                        )}
                        {contact.state === ContactStateEnum.Active && (
                          <small>[active]</small>
                        )}
                      </div>
                      {contact.currentEmail && (
                        <small className="block">{contact.currentEmail}</small>
                      )}
                      {contact.currentPhone && (
                        <small className="block">{contact.currentPhone}</small>
                      )}
                    </li>
                  ))}
                </ol>
              </div>
            )}
          </div>
          <div className="md:absolute right-0 top-0 flex h-full mt-1 md:mt-0 md:py-[2px] md:pr-[2px]">
            <Button
              className="w-full md:w-24 items-stretch justify-self-stretch !rounded-none text-center"
              type="submit"
              label="Add"
              size="sm"
              onClick={(e) => {
                e.preventDefault()
                onSubmit()
              }}
              disabled={rawInput.length === 0 || loading}
            />
          </div>
        </div>
      </label>
      <small>
        e.g.{" "}
        <span className="bg-gray-100 dark:bg-gray-700 px-1 rounded">
          Jack O'Neill, 917-555-5555 -- I met this person at a conference
        </span>{" "}
      </small>
    </form>
  )
}

const ADD_CONTACT_MUTATION = gql(`
  mutation AddContact($input: AddContactInput!) {
    addContact(input: $input) {
      contact {
        id
        name
        state
      }
    }
  }
`)

const SEARCH_CONTACTS_QUERY = gql(`
  query SearchContacts($query: String!) {
    searchContacts(query: $query) {
      nodes {
        id
        name
        currentEmail
        currentPhone
        state
      }
    }
  }
`)
