import { Link, Outlet } from "react-router-dom"
import {
  adminPath,
  adminUsersIndexPath,
  goodJobPath,
  landingPath,
} from "~/common/paths"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"

export const AdminLayout = () => {
  const {
    result: { loading: currentUserLoading },
  } = useCurrentUserMaybe()
  return (
    <>
      <header>
        <nav className="mx-auto p-4 pb-0 lg:px-8" aria-label="Global">
          <div className="flex flex-wrap items-center justify-between border-b dark:border-gray-600 pb-4 gap-4">
            <div className="flex-1">
              <Link
                to={adminPath.pattern}
                className="-m-1.5 p-1.5 font-bold text-2xl text-pink-600 no-underline hover:underline"
              >
                Call List Admin Panel
              </Link>
            </div>
            <div>
              <a href={goodJobPath.pattern}>Jobs</a>
            </div>
            <div>
              <Link to={adminUsersIndexPath.pattern}>Users</Link>
            </div>
            <div>
              <Link to={landingPath.pattern}>Back to app</Link>
            </div>
          </div>
        </nav>
      </header>

      <div className="w-100 px-4 lg:px-8 py-8">
        {!currentUserLoading && <Outlet />}
      </div>
    </>
  )
}
