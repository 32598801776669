import clsx from "clsx"
import { useEffect, useState } from "react"

type LoadingIndicatorProps = {
  size?: "small"
}

export const LoadingIndicator = (props: LoadingIndicatorProps) => (
  <div role="status">
    <svg
      aria-hidden="true"
      className={clsx(
        "mr-2 text-gray-200 dark:text-opacity-25 animate-spin fill-pink-600",
        {
          "w-5 h-5": props.size === "small",
          "w-6 h-6": props.size == null,
        }
      )}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24,12.5 C24,18.8512844 18.851266,24.0000414 12.5,24.0000414 C6.148734,24.0000414 1,18.8512844 1,12.5 C1,6.1487294 6.148734,1 12.5,1 C18.851266,1 24,6.1487294 24,12.5 Z M3.0887312,12.4999954 C3.0887312,17.6976964 7.302299,21.9112734 12.5,21.9112734 C17.697701,21.9112734 21.911278,17.6976964 21.911278,12.4999954 C21.911278,7.3022944 17.697701,3.0887312 12.5,3.0887312 C7.302299,3.0887312 3.0887312,7.3022944 3.0887312,12.4999954 Z"
        fill="currentColor"
      />
      <path
        d="M12.5,1 C18.851266,1 24,6.1487294 24,12.5 L21.911278,12.5 C21.911278,7.3022944 17.697701,3.0887312 12.5,3.0887312 L12.5,1 Z"
        fill="currentFill"
      />
    </svg>
    <span className="sr-only">Loading …</span>
  </div>
)

export const LoadingIndicatorCentered = () => (
  <div className="flex flex-1 justify-center items-center h-full">
    <FadeIn>
      <LoadingIndicator />{" "}
    </FadeIn>
  </div>
)

export const FadeIn = ({ children }: { children: React.ReactNode }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <div
      className={`transition-opacity duration-1000  ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      {children}
    </div>
  )
}
