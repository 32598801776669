import { Link } from "react-router-dom"
import { QueryResult, useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { UploadContactsForm } from "~/contacts/UploadContactsForm"
import invariant from "tiny-invariant"
import clsx from "clsx"
import { ContactUploadsQuery, Exact } from "~/__generated__/graphql"
import { format, parseISO } from "date-fns"
import { useSafePolling } from "~/common/useSafePolling"
import { useCurrentUser } from "~/auth/CurrentUserContext"

export const UploadContactsScreen = () => {
  const result = useQuery(CONTACT_UPLOADS_QUERY_DOCUMENT)
  useSafePolling(result, 2000)
  const currentuser = useCurrentUser()

  return (
    <div>
      <div className="bg-green-200/40 p-4 md:p-6 mb-8 rounded">
        <p>
          Add your existing address book to Call List to super-charge your
          experience. You can email{" "}
          <Link to={`mailto:${currentuser.callListEmail}`}>
            {currentuser.callListEmail}
          </Link>{" "}
          and attach a .vcf or .csv file, or just upload your file below. If
          you're using a .csv file, please ensure the columns are in the order
          of first_name, last_name, email, and phone. If you're using a .vcf
          file please do not include images or photos.
        </p>
        <p>
          <Link to="https://docs.calllist.app/">
            &rarr; View our video walkthrough
          </Link>
        </p>
      </div>
      <UploadContactsForm afterUpload={() => result.refetch()} />
      <div className="mt-10" />
      <UploadsTable result={result} />
    </div>
  )
}

const UploadsTable = ({
  result,
}: {
  result: QueryResult<ContactUploadsQuery, Exact<{ [key: string]: never }>>
}) => {
  if (result.error) return <div>error</div>
  if (result.loading) return <div>loading …</div>
  invariant(result.data)

  if (result.data?.contactUploads.nodes.length === 0) return null

  return (
    <>
      <div className="sm:flex items-baseline justify-between">
        <h2>Uploads</h2>
      </div>
      <div className="overflow-x-auto mt-8">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <TableCell heading>Filename</TableCell>
              <TableCell heading>State</TableCell>
              <TableCell heading>Created</TableCell>
              <TableCell heading># Imported</TableCell>
            </tr>
          </thead>
          <tbody>
            {result.data.contactUploads.nodes.map((upload) => {
              return (
                <tr key={upload.id}>
                  <TableCell> {upload.filename} </TableCell>
                  <TableCell>{upload.state}</TableCell>
                  <TableCell>
                    {format(parseISO(upload.createdAt), "MMM d, y @ h:mmaaaaa")}
                  </TableCell>
                  <TableCell>{upload.contactCount}</TableCell>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

const CONTACT_UPLOADS_QUERY_DOCUMENT = gql(`
  query ContactUploads {
    contactUploads {
      nodes {
        id
        filename
        createdAt
        state
        contactCount
      }
    }
  }
`)

export const TableCell = ({
  className,
  children,
  heading,
}: {
  children: React.ReactNode
  heading?: boolean
  className?: string
}) => {
  return (
    <td
      className={clsx(
        "border border-black p-2 dark:border-white",
        className,
        heading && "font-bold bg-gray-200 dark:bg-gray-700 align-top"
      )}
    >
      {children}
    </td>
  )
}
