import { gql } from "~/__generated__"

// This file is parsed also used by ruby, don't add anything to it aside from this query

export const CURRENT_USER_QUERY_DOCUMENT = gql(`
  query CurrentUserProvider {
    currentUser {
      id
      isAdmin
      inboundEmail
      callListEmail
      callListUrl
      formPath
      name
      email
      phone
      hasPaidAccess
      subscriptionStatus
      canceledButPaidThru
      dailyCallListEnabled
      emailAvailabilityFormEnabled
      requireCallNotes
      emailTemplate
      pendingEmailTemplate
      emailCopy
      noteTemplate
      pendingNoteTemplate
      noteCopy
      textTemplate
      pendingTextTemplate
      textCopy
      activationEmailTemplate
      pendingActivationEmailTemplate
      hasStripeCustomer
      enrichment
      __typename
    }
  }
`)
