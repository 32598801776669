import { useParams, useSearchParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Button } from "~/ui/Button"

export const EmailUnsubscribeScreen = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()

  const [createEmailUnsubscribe, { data, loading }] =
    useSafeMutation(UNSUBSCRIBE_MUTATION)

  const unsubscribe = async () => {
    const { errors } = await createEmailUnsubscribe({
      variables: {
        input: {
          userId: params.userId || "",
          email: searchParams.get("email") || "",
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    }
  }

  console.log(params, searchParams.get("email"))
  return (
    <div className="max-w-3xl mx-auto">
      <h2>Unsubscribe</h2>
      <p className="mb-4">Are you sure you want to unsubscribe?</p>

      {data ? (
        <p>Successfully unsubscribed.</p>
      ) : (
        <Button
          label="Unsubscribe"
          disabled={loading}
          onClick={() => unsubscribe()}
        />
      )}
    </div>
  )
}

const UNSUBSCRIBE_MUTATION = gql(`
  mutation CreateEmailUnsubscribe($input: CreateEmailUnsubscribeInput!) {
    createEmailUnsubscribe(input: $input) {
      success
    }
  }
`)
