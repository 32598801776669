import { useQuery } from "@apollo/client"
import { groupBy, upperFirst } from "lodash"
import { gql } from "~/__generated__"
import { ContactCard } from "~/contacts/ContactCard"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { Error } from "~/ui/Error"

export const PendingContactsScreen = () => {
  const { data, loading, error } = useQuery(PENDING_CONTACTS_QUERY)

  if (loading && !data) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading pending contacts" />

  const groupedContacts = groupBy(data.pendingContacts.nodes, "source")

  return (
    <div>
      {data.pendingContacts.nodes.length > 0 ? (
        <>
          {Object.keys(groupedContacts).map((source, index) => (
            <div key={source} className={index > 0 ? "mt-8" : ""}>
              <h3>
                Source: {source === "null" ? "Organic" : upperFirst(source)}
              </h3>
              {groupedContacts[source].map((contact) => (
                <ContactCard
                  className="mt-4"
                  key={contact.id}
                  contact={contact}
                  emailPromptWhenActivated
                />
              ))}
            </div>
          ))}
        </>
      ) : (
        <p>You do not have any pending contacts to manage.</p>
      )}
    </div>
  )
}

export const PENDING_CONTACTS_QUERY = gql(`
  query PendingContacts {
    pendingContacts {
      nodes {
        id
        ...Contact_ContactList
      }
    }
  }
`)
