import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { dashboardPath, signUpPath } from "~/common/paths"

export const LandingScreen = () => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUserMaybe()

  useEffect(() => {
    if (currentUser) navigate(dashboardPath.pattern)
  }, [currentUser, navigate])

  return (
    <div className="max-w-3xl mx-auto">
      <h1>Stop Living Your Life Like a ‘FIFO’ Robot&nbsp;…</h1>
      <p className="mt-6">
        Only n00bs wake up every morning and mindlessly follow their calendar
        from meeting to meeting, day after day. Serious people use Call List to
        prioritize their key outreach every day to make sure the most important
        interactions&nbsp;happen.
      </p>
      <div className="mt-6 font-bold">
        <Link to={signUpPath.pattern}>Sign up&nbsp;&rarr;</Link>
      </div>
      <hr className="my-8" />
      <h2>How It Works</h2>
      <p className="mt-6">
        Call List is the Calendly alternative for busy people modeled off how
        studio executives, investment bankers, and other folks in ‘dynamic’
        worlds&nbsp;work.
      </p>
      <p>
        Rather than filling up ‘slots’, Call Lists helps you build and
        prioritize who you need to talk to every day and reach out to them when
        they are free. Key&nbsp;features:
      </p>
      <ul className="list-disc ms-6 mt-6">
        <li>
          Build and prioritize your ‘call list’ of folks to connect&nbsp;with
        </li>
        <li>Custom call scheduling link to collect contact&nbsp;details</li>
        <li>‘AI’ driven availability prediction</li>
        <li>Auto-texts to check who is free to&nbsp;talk</li>
        <li>Smart recurrance features</li>
        <li>Morning email briefings</li>
        <li>and more&nbsp;…</li>
      </ul>
      <p className="mt-4 font-bold">
        <Link to="https://docs.calllist.app/">
          See it in action&nbsp;&rarr;
        </Link>
      </p>
      <hr className="my-8" />
      <h2>Reviews</h2>
      <blockquote className="mt-6 ps-4 border-l-4 border-gray-100">
        <p>
          This is a way better way to operate. Stacking meetings sucks, just
          call&nbsp;people.
        </p>
        <footer className="mt-4 text-sm">– Famous VC</footer>
      </blockquote>
      <blockquote className="mt-6 ps-4 border-l-4 border-gray-100">
        <p>
          Call List does automatically what I have been doing with my assistant
          in Hollywood for the last 40 years. If only this existed before I
          would have 10 more&nbsp;Oscars!
        </p>
        <footer className="mt-4 text-sm">– Famous Producer</footer>
      </blockquote>
      <blockquote className="mt-6 ps-4 border-l-4 border-gray-100">
        <p>
          Shit, now everyone else can do what I do with software — there goes
          the neighborhood&nbsp;…
        </p>
        <footer className="mt-4 text-sm">– Investment Banker</footer>
      </blockquote>
    </div>
  )
}
