import invariant from "tiny-invariant"

export const getStripeKey = () => {
  const stripeKeyMetaTag = document.querySelector(
    "meta[name=stripe-publishable-key]"
  )
  invariant(stripeKeyMetaTag)
  const stripePublishableKey = stripeKeyMetaTag.getAttribute("content")
  invariant(stripePublishableKey)
  return stripePublishableKey
}
