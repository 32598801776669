import { DocumentNode } from "graphql"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import Mustache from "mustache"
import { gql } from "~/__generated__"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useActivateContact } from "~/common/useActivateContact"
import { displayErrors } from "~/common/validations"
import { TextField } from "../ui/formFields/TextField"
import { Button } from "~/ui/Button"
import { Contact } from "~/__generated__/graphql"

interface EmailActivatedContactFormProps {
  contact: Contact
  refetchQueries: DocumentNode[]
  onCancel: () => void
}

const SEND_ACTIVATION_EMAIL_MUTATION = gql(`
  mutation SendActivationEmail($input: SendActivationEmailInput!) {
    sendActivationEmail(input: $input) {
      success
    }
  }
`)

export const EmailActivatedContactForm = ({
  contact,
  refetchQueries,
  onCancel,
}: EmailActivatedContactFormProps) => {
  const currentUser = useCurrentUser()
  const [sendEmail, { loading }] = useSafeMutation(
    SEND_ACTIVATION_EMAIL_MUTATION
  )
  const { activateContact, loading: activatingInProgress } = useActivateContact(
    { refetchQueries }
  )

  const onSubmit = async (values: any) => {
    activateContact(contact.id)

    const { errors } = await sendEmail({
      variables: {
        input: {
          contactId: contact.id,
          ...values,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      toast.success(`Email sent to ${contact.name}`)
    }
  }

  const initialValues = {
    emailBody: Mustache.render(currentUser.activationEmailTemplate, {
      contact_name: contact.firstName,
      user_name: currentUser.name,
    }),
  }

  const validationSchema = Yup.object({
    emailBody: Yup.string().required(),
  })

  return (
    <div className="flex fixed top-0 left-0 z-30 w-full h-full bg-white dark:bg-black overflow-hidden p-4">
      <div className="border-2 p-4 bg-white dark:bg-black border-black  dark:border-white !m-auto h-full md:max-h-min overflow-y-auto w-full max-w-7xl">
        <h2>Let {contact.name} know they have been added to your queue</h2>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {() => (
            <Form className="flex-1">
              <TextField
                name="emailBody"
                className="w-full"
                labelClassName="mt-4"
                label="Email body"
                as="textarea"
                minRows={6}
              />
              <div className="w-full flex gap-2 md:w-[unset] mt-4">
                <Button
                  label="Send"
                  size="sm"
                  type="submit"
                  disabled={loading}
                />
                <Button
                  label="Skip"
                  size="sm"
                  onClick={() => activateContact(contact.id)}
                  disabled={activatingInProgress}
                />
                <Button
                  label="Cancel"
                  size="sm"
                  color="secondary"
                  disabled={loading}
                  onClick={onCancel}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
