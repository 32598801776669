import { Outlet, NavLink } from "react-router-dom"
import {
  settingsPath,
  accountSettingsPath,
  uploadContactsPath,
} from "~/common/paths"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"
import { tabLinkClassNames } from "~/util/tabLinkClassNames"

export const SettingsLayout = () => {
  const {
    currentUser,
    result: { loading: currentUserLoading },
  } = useCurrentUserMaybe()

  if (!currentUser || currentUserLoading) {
    return null
  }

  return (
    <div className="max-w-7xl m-auto">
      <nav className="mb-8 overflow-hidden overflow-x-auto">
        <div className="border-b dark:border-gray-600 flex">
          <NavLink to={settingsPath.pattern} end className={tabLinkClassNames}>
            Settings
          </NavLink>
          <NavLink
            to={accountSettingsPath.pattern}
            className={tabLinkClassNames}
          >
            Account
          </NavLink>
          <NavLink
            to={uploadContactsPath.pattern}
            className={tabLinkClassNames}
          >
            Upload Contacts
          </NavLink>
        </div>
      </nav>
      <Outlet />
    </div>
  )
}
