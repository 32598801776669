import { QueryResult } from "@apollo/client"
import { useEffect, useMemo } from "react"

// This hook works around the following issue:
// useQuery pollInterval with cache-and-network doesnt stop after unmounting
// https://github.com/apollographql/apollo-client/issues/9431

export const useSafePolling = (
  result: QueryResult<any, any>,
  pollIntervalMs: number
) => {
  const { startPolling, stopPolling } = useMemo(() => result, [result])

  useEffect(() => {
    startPolling(pollIntervalMs)

    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling, pollIntervalMs])
}
