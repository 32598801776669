// use static-path to generate type-safe paths
// https://github.com/garybernhardt/static-path

import { path } from "static-path"

// IF YOU ARE ADDING A ROOT LEVEL PATH MAKE SURE YOU
// ADD IT TO THE RESERVED PATHS LIST IN app/model/User.rb

export const landingPath = path("/")
export const signInPath = path("/sign-in")
export const signUpPath = path("/sign-up")
export const dashboardPath = path("/dashboard")
export const pendingPath = path("/dashboard/requests")
export const archivedPath = path("/dashboard/contacts")
export const notFoundPath = path("/not-found")
export const settingsPath = path("/settings")
export const accountSettingsPath = path("/settings/account")
export const uploadContactsPath = path("/settings/upload-contacts")
export const subscribePath = path("/subscribe")
export const aboutPath = path("/about")
export const termsPath = path("/terms")
export const privacyPath = path("/privacy")
export const emailUnsubscribePath = path("/email-unsubscribe/:userId")

export const adminPath = path("/admin")
export const adminUsersIndexPath = path("/admin/users")
export const adminUserUpdatePath = path("/admin/users/:userId/edit")
export const adminUserCreatePath = path("/admin/users/new")

// server paths
export const signOutPath = path("/users/sign_out")
export const goodJobPath = path("/good_job")
export const customerPortalPath = path("/customer-portal")
export const contactUploadsPostPath = path("/contact_uploads")
