import { useNavigate } from "react-router-dom"
import { FormikHelpers } from "formik"
import toast from "react-hot-toast"
import { displayErrors } from "~/common/validations"
import { Breadcrumb } from "~/ui/Breadcrumb"
import { AdminUserForm, UserFormFields } from "~/admin/AdminUserForm"
import { adminUsersIndexPath } from "~/common/paths"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { EnrichmentStatus } from "~/__generated__/graphql"

const CREATE_USER_MUTATION = gql(`
  mutation AdminCreateUserMutation($input: UserCreateInput!) {
    adminUserCreate(input: $input) {
      user {
        id
      }
    }
  }
`)

export const AdminUserCreateScreen = () => {
  const navigate = useNavigate()
  const [userMutation] = useSafeMutation(CREATE_USER_MUTATION)

  const initialValues: UserFormFields = {
    email: "",
    name: "",
    phone: "",
    isAdmin: false,
    hasCompAccess: false,
    enrichment: EnrichmentStatus.Off,
    referralCode: "",
    overrideStripePriceId: "",
  }

  const onSubmit = async (
    values: UserFormFields,
    { setFieldError, setSubmitting }: FormikHelpers<UserFormFields>
  ) => {
    const { errors } = await userMutation({
      variables: {
        input: {
          userInput: values,
        },
      },
    })

    if (errors) {
      displayErrors(errors, setFieldError)
    } else {
      toast.success("User created")
      navigate(adminUsersIndexPath.pattern)
    }

    setSubmitting(false)
  }

  return (
    <div className="max-w-3xl m-auto">
      <Breadcrumb path={adminUsersIndexPath.pattern} label="Users" />
      <div className="my-8">
        <h1>New User</h1>
      </div>
      <AdminUserForm initialValues={initialValues} onSubmit={onSubmit} />
    </div>
  )
}
