import { useState } from "react"
import { Link } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { ContactCard } from "~/contacts/ContactCard"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { Error } from "~/ui/Error"
import { uploadContactsPath } from "~/common/paths"
import { useDebounce } from "@uidotdev/usehooks"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { EnrichmentStatus } from "~/__generated__/graphql"

export const ArchivedContactsScreen = () => {
  const viewer = useCurrentUser()

  const [rawInput, setRawInput] = useState("")
  const inputDebounced = useDebounce(
    rawInput,
    rawInput.startsWith("ai:") ? 500 : 100
  )

  const { loading, error, fetchMore, ...result } = useQuery(
    ARCHIVED_CONTACTS_QUERY,
    {
      variables: { after: null, query: inputDebounced },
    }
  )

  const data = result.data ?? result.previousData

  const noResults =
    rawInput && !data?.archivedContacts.edges.length ? (
      <p>No contacts match ”{rawInput}”</p>
    ) : (
      <p>
        To add your contacts from your iOS address book follow the instructions{" "}
        <Link to={uploadContactsPath.pattern}>here</Link>
      </p>
    )

  const isAi = rawInput.startsWith("ai:")
  const aiEnabled = viewer.enrichment === EnrichmentStatus.All

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <label>
          <span className="font-bold sr-only">Search all contacts</span>
          <div className="relative">
            <div className="relative">
              <div className="flex w-full gap-2 items-center">
                <input
                  type="search"
                  value={rawInput}
                  onChange={(e) => setRawInput(e.target.value)}
                  className="w-full border-2 border-black dark:bg-black dark:border-white md:text-xl pr-16"
                  placeholder="Search by name or email"
                  // onKeyDown={handleKeyDown}
                />
                {aiEnabled && (
                  <div className="ml-auto flex-shrink-0 flex gap-2 items-center absolute end-0 pr-3">
                    <input
                      type="checkbox"
                      id="ai-checkbox"
                      className="flex"
                      checked={isAi}
                      onChange={(ev) => {
                        if (ev.target.checked) {
                          setRawInput((prev) => `ai:${prev}`)
                        } else {
                          setRawInput((prev) => prev.replace(/^ai:/, ""))
                        }
                      }}
                    />
                    <label htmlFor="ai-checkbox" className="flex">
                      AI
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </label>
      </form>
      <div className="mt-8">
        {error && <Error message="Error loading archived contacts" />}
        {loading && !data ? (
          <LoadingIndicatorCentered />
        ) : (
          <>
            {!!data?.archivedContacts.edges.length
              ? data.archivedContacts.edges
                  .map((e) => e.node)
                  .map((contact) => (
                    <ContactCard
                      className="mt-4"
                      key={contact.id}
                      contact={contact}
                    />
                  ))
              : noResults}
          </>
        )}
      </div>
      {data && data.archivedContacts.pageInfo.hasNextPage && (
        <div className="mt-8">
          <button
            type="button"
            onClick={() => {
              fetchMore({
                variables: {
                  after: data.archivedContacts.pageInfo.endCursor,
                },
              })
            }}
            disabled={loading}
            className="underline hover:text-pink-600"
          >
            Load more …
          </button>
        </div>
      )}
    </>
  )
}

export const ARCHIVED_CONTACTS_QUERY = gql(`
  query ArchivedContacts($query: String, $after: String) {
    archivedContacts(query: $query, first: 20, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          ...Contact_ContactList
        }
      }
      totalCount
    }
  }
`)
