import { Formik, Form, FormikHelpers } from "formik"
import * as Yup from "yup"
import { Fieldset } from "~/ui/Fieldset"
import { TextField } from "~/ui/formFields/TextField"
import { CheckBoxField } from "~/ui/formFields/CheckBoxField"
import { Button } from "~/ui/Button"
import { EnrichmentStatus } from "~/__generated__/graphql"
import DropdownField from "~/ui/formFields/DropdownField"

export type UserFormFields = {
  email: string
  name: string
  phone: string
  isAdmin: boolean
  hasCompAccess: boolean
  enrichment: EnrichmentStatus
  referralCode: string
  overrideStripePriceId: string
}

interface AdminUserFormProps {
  initialValues: UserFormFields
  onSubmit: (
    values: UserFormFields,
    helpers: FormikHelpers<UserFormFields>
  ) => void
}

const validationSchema = Yup.object({
  email: Yup.string().email().required("Email is required"),
  name: Yup.string().required("Name is required"),
  isAdmin: Yup.boolean(),
  hasCompAccess: Yup.boolean(),
})

export const AdminUserForm = ({
  initialValues,
  onSubmit,
}: AdminUserFormProps) => {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, ...formik }) => (
        <Form>
          <Fieldset legend="Personal details">
            <TextField label="Name" name="name" />
            <TextField label="Email" name="email" labelClassName="mt-4" />
            <TextField label="Phone" name="phone" labelClassName="mt-4" />
          </Fieldset>

          <Fieldset legend="Advanced" className="mt-8">
            <TextField
              label="Override Stripe Price ID"
              name="overrideStripePriceId"
            />
          </Fieldset>

          {/* <Fieldset legend="Settings" className="mt-8"></Fieldset> */}

          <Fieldset legend="Permissions" className="mt-8">
            <CheckBoxField label="Admin" name="isAdmin" />
            <CheckBoxField
              label="Complimentary Access"
              name="hasCompAccess"
              className="mb-2"
            />

            <DropdownField
              name="enrichment"
              label="Enrichment"
              options={enrichmentOptions}
            />
          </Fieldset>

          <div className="mt-8">
            <Button type="submit" disabled={isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  )
}

const enrichmentOptions: { value: EnrichmentStatus; label: string }[] = [
  { value: EnrichmentStatus.Off, label: EnrichmentStatus.Off },
  {
    value: EnrichmentStatus.CallLink,
    label: `${EnrichmentStatus.CallLink} – Only enrich contacts added through call link`,
  },
  {
    value: EnrichmentStatus.All,
    label: `${EnrichmentStatus.All} – Enrich all contacts`,
  },
]
