import { ApolloError } from "@apollo/client"
import { GraphQLError } from "graphql"
import { camelCase, capitalize } from "lodash"
import { toast } from "react-hot-toast"

export type SetFieldErrorType = (
  field: string,
  message: string | undefined
) => void

const attributeDisplayName = (name: string) =>
  capitalize(name.replace("_", " "))

const showFieldErrors = (
  key: string,
  errors: string[],
  setFieldError: SetFieldErrorType
) => {
  const errorMessages = errors?.map(
    (error) => `${attributeDisplayName(key)} ${error}`
  )
  if (errorMessages?.length) {
    setFieldError(camelCase(key), `${errorMessages.join(", ")}`)
  }
}

export const displayErrors = (
  errors?: readonly GraphQLError[],
  setFieldError?: SetFieldErrorType
) => {
  // This is a nasty typing issue where Apollo is saying that it will return a type of
  // GraphQLError[] but actually returning ApolloError
  // @ts-expect-error
  const apolloError = errors as ApolloError

  errors = apolloError.graphQLErrors

  const validationErrors = errors?.find(
    (error) => error.extensions?.code === "VALIDATION_ERROR"
  )

  if (validationErrors) {
    toast.error(
      validationErrors.message || "An error has occurred, please try again"
    )

    if (setFieldError) {
      const fields = Object.keys(validationErrors.extensions).filter(
        (key) => key !== "code"
      )
      fields.forEach((key) => {
        const errors = validationErrors.extensions[key] as string[]
        showFieldErrors(key, errors, setFieldError)
      })
    }
  } else {
    toast.error(
      apolloError.message || "An error has occurred, please try again"
    )
  }
}
