import { useState } from "react"
import { Link } from "react-router-dom"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/ui/Button"
import { signUpPath } from "~/common/paths"

const SEND_MAGIC_LINK_MUTATION = gql(`
  mutation SendMagicLink($input: SendMagicLinkInput!) {
    sendMagicLink(input: $input) {
      success
    }
  }
`)

export const SignInScreen = () => {
  const [sendMagicLink, { loading }] = useSafeMutation(SEND_MAGIC_LINK_MUTATION)
  const [email, setEmail] = useState("")

  const onSubmit = async () => {
    const { data } = await sendMagicLink({
      variables: {
        input: {
          email,
        },
      },
    })

    if (data?.sendMagicLink.success) {
      setEmail("")
      toast.success("Magic link sent")
    } else {
      toast.error("Error sending magic link")
    }

    console.log(data)
  }

  return (
    <div className="max-w-md m-auto">
      <h1>Sign in</h1>
      <form className="my-8">
        <label className="block">
          <span>Email</span>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border-2 border-black dark:border-white dark:bg-black"
            placeholder="Enter your email"
          />
        </label>
        <Button
          className="btn mt-8 w-full"
          onClick={onSubmit}
          disabled={email.length === 0 || loading}
          label="Send magic link"
          type="submit"
        />
      </form>

      <div className="text-center">
        Need an account? <Link to={signUpPath.pattern}>Sign up</Link>
      </div>
    </div>
  )
}
