import { useArchiveContact } from "~/common/useArchiveContact"
import { useActivateContact } from "~/common/useActivateContact"
import { useDeleteContact } from "~/common/useDeleteContact"
import { DocumentNode } from "graphql"
import { Button } from "~/ui/Button"

export const ActivateButton = ({
  contactId,
  refetchQueries,
}: {
  contactId: string
  refetchQueries: DocumentNode[]
}) => {
  const { activateContact, loading: activatingInProgress } = useActivateContact(
    { refetchQueries }
  )

  return (
    <Button
      size="sm"
      label="Activate"
      onClick={(e) => {
        e.stopPropagation() // This prevents the click from propagating to the card itself
        activateContact(contactId)
      }}
      disabled={activatingInProgress}
    />
  )
}

export const ArchiveButton = ({
  label = "Archive",
  contactId,
  refetchQueries,
  afterArchive,
}: {
  label?: string
  contactId: string
  afterArchive?: () => void
  refetchQueries: DocumentNode[]
}) => {
  const { archiveContact, loading: archivingInProgress } = useArchiveContact({
    refetchQueries,
  })
  return (
    <Button
      size="sm"
      label={label}
      onClick={(e) => {
        e.stopPropagation() // This prevents the click from propagating to the card itself
        archiveContact(contactId)

        if (afterArchive) {
          afterArchive()
        }
      }}
      disabled={archivingInProgress}
    />
  )
}

export const DeleteButton = ({
  contactId,
  refetchQueries,
}: {
  contactId: string
  refetchQueries: DocumentNode[]
}) => {
  const { deleteContact, loading: deletingInProgress } = useDeleteContact({
    refetchQueries,
  })
  return (
    <Button
      size="sm"
      label="Delete"
      color="danger"
      onClick={(e) => {
        e.stopPropagation() // This prevents the click from propagating to the card itself

        if (window.confirm("Are you sure? This cannot be undone.")) {
          deleteContact(contactId)
        }
      }}
      disabled={deletingInProgress}
    />
  )
}
