import * as React from "react"
import { RouterProvider } from "react-router-dom"
import { Toaster } from "react-hot-toast"
import colors from "tailwindcss/colors"
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client"
import { relayStylePagination } from "@apollo/client/utilities"
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev"
import { createConsumer } from "@rails/actioncable"
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink"
import { router } from "./router"
import { CurrentUserProvider } from "./auth/CurrentUserContext"
import { loggingFetch } from "./util/loggingFetch"

// @ts-expect-error this is a vite-only feature
if (import.meta.env.DEV) {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

const cable = createConsumer()

const hasSubscriptionOperation = ({ query: { definitions } }: any) => {
  return definitions.some(
    ({ kind, operation }: any) =>
      kind === "OperationDefinition" && operation === "subscription"
  )
}

const csrfEl = document.querySelector("meta[name=csrf-token]")
const csrfToken = csrfEl ? csrfEl.getAttribute("content") : undefined

if (csrfToken == null) {
  console.warn("missing csrf token")
}

const httpLink = new HttpLink({
  credentials: "same-origin",
  headers: {
    "X-CSRF-Token": csrfToken!,
    "X-CL-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  fetch: loggingFetch,
})

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
)

const apolloClient = new ApolloClient({
  uri: "/graphql",
  link,
  defaultOptions: {
    // useQuery uses watchQuery
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          archivedContacts: relayStylePagination(["query", "first"]),
        },
      },
    },
  }),
})

export const App = () => {
  return (
    <React.StrictMode>
      <ApolloProvider client={apolloClient}>
        <CurrentUserProvider>
          <Toaster
            toastOptions={{
              style: {
                color: colors.white,
                maxWidth: "100%",
                textAlign: "left",
              },
              success: {
                style: {
                  backgroundColor: colors.pink[600],
                },
              },
              error: {
                style: {
                  backgroundColor: colors.red[700],
                },
              },
            }}
          />
          <RouterProvider router={router} />
        </CurrentUserProvider>
      </ApolloProvider>
    </React.StrictMode>
  )
}
