import { InputHTMLAttributes } from "react"
import TextareaAutosize from "react-textarea-autosize"
import { Field, useField, FieldAttributes } from "formik"
import clsx from "clsx"
import { upperFirst } from "lodash"
import { FieldError } from "~/ui/FieldError"

type TextFieldProps = {
  as?: string
  className?: string
  label?: string
  labelClassName?: string
  containerClassName?: string
  labelHidden?: boolean
  required?: boolean
} & InputHTMLAttributes<HTMLInputElement> &
  FieldAttributes<any>

export const TextField = ({
  as,
  name,
  label,
  labelHidden = false,
  labelClassName,
  containerClassName,
  className,
  required,
  ...fieldProps
}: TextFieldProps) => {
  const [field, meta] = useField(name)

  return (
    <label className={clsx("block", containerClassName)}>
      <span
        className={clsx(
          "block font-bold",
          labelClassName,
          labelHidden && "sr-only"
        )}
      >
        {upperFirst(label ?? name)}
        {required && <sup className="text-red-700"> *</sup>}
      </span>
      {as === "textarea" ? (
        <TextareaAutosize
          id={name}
          className={clsx(
            "w-full border-2 border-black dark:border-white dark:bg-black",
            meta.touched && meta.error && "border-red-700",
            fieldProps.disabled &&
              "bg-gray-100 dark:bg-gray-800 cursor-not-allowed",
            className
          )}
          {...field}
          {...fieldProps}
        />
      ) : (
        <Field
          id={name}
          className={clsx(
            "w-full border-2 border-black dark:border-white dark:bg-black",
            meta.touched && meta.error && "border-red-700",
            fieldProps.disabled &&
              "bg-gray-100 dark:bg-gray-800 cursor-not-allowed",
            className
          )}
          {...field}
          {...fieldProps}
        />
      )}
      {meta.touched && meta.error && <FieldError message={meta.error} />}
    </label>
  )
}
