import clsx from "clsx"
import { Field, useField } from "formik"
import { FieldError } from "~/ui/FieldError"

interface CheckBoxFieldProps {
  className?: string
  disabled?: boolean
  label?: string
  name: string
  value?: string | boolean
}

export const CheckBoxField = (props: CheckBoxFieldProps) => {
  const { className, label, name, ...checkboxProps } = props
  const [field, meta, helpers] = useField(name)

  return (
    <div className={className}>
      <label
        className={clsx("flex", meta.touched && meta.error && "text-red-700")}
      >
        <Field
          type="checkbox"
          id={name}
          checked={field.value}
          {...field}
          {...checkboxProps}
          onChange={() => helpers.setValue(!field.value)}
        />
        <span className="ml-3 -mt-1">{label}</span>
      </label>
      {meta.touched && meta.error && <FieldError message={meta.error} />}
    </div>
  )
}
