import clsx from "clsx"
import { FormEventHandler, useReducer, useState } from "react"
import { useDropzone, FileWithPath } from "react-dropzone"
import toast from "react-hot-toast"
import { contactUploadsPostPath } from "~/common/paths"
import { Button } from "~/ui/Button"
import { getMetaVar } from "~/util/getMetaVar"

const SUCCESS_MESSAGE =
  "Contacts file uploaded.  It will take a moment to process."
const ERROR_MESSAGE =
  "Error uploading file. Please make sure it's a valid vcard (.vcf) or csv (.csv) file and contact us if you're having trouble."

export const UploadContactsForm = ({
  afterUpload,
}: {
  afterUpload: () => void
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      maxFiles: 1,
      multiple: false,
      accept: {
        "text/csv": [".csv"],
        "text/x-vcard": [".vcf"],
      },
    })

  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const csrfToken = getMetaVar("csrf-token")
  const selectedFile: FileWithPath = acceptedFiles[0]

  const resetDrop = () => {
    // This is weird, but dropzone doesn't give us a way to reset the state
    // https://github.com/react-dropzone/react-dropzone/issues/805
    acceptedFiles.splice(0, acceptedFiles.length)
    forceUpdate()
    afterUpload()
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    var formdata = new FormData()
    formdata.append("contacts_file", selectedFile)
    formdata.append("authenticity_token", csrfToken)

    setIsUploading(true)

    try {
      const response = await fetch(contactUploadsPostPath.pattern, {
        method: "POST",
        body: formdata,
      })
      if (response.ok) {
        toast.success(SUCCESS_MESSAGE)
      } else {
        toast.error(ERROR_MESSAGE)
      }
    } catch (error) {
      console.log(error)
      toast.error(ERROR_MESSAGE)
    }
    setIsUploading(false)
    resetDrop()
  }

  return (
    <form onSubmit={onSubmit}>
      <div
        {...getRootProps()}
        className={clsx("flex items-center border-2 px-6 py-4 cursor-pointer", {
          "border-slate-200": !isDragActive,
          "border-blue-400": isDragActive,
          hidden: !!selectedFile,
        })}
      >
        <input {...getInputProps()} />
        <Button label="Choose" size="sm" className="mr-4" />
        <p>Drag and drop a .csv or .vcf file here, or click to select a file</p>
      </div>

      {selectedFile && (
        <div
          className={clsx(
            "flex items-center border-2 px-6 py-4 border-black dark:border-white"
          )}
        >
          <p>{selectedFile.path}</p>
          <Button
            label="Clear"
            color="secondary"
            className="ml-auto"
            size="sm"
            onClick={resetDrop}
          />
          <Button
            label={isUploading ? "Uploading..." : "Upload"}
            className="ml-3"
            size="sm"
            type="submit"
            disabled={isUploading}
          />
        </div>
      )}
    </form>
  )
}
