import { Link, Outlet } from "react-router-dom"
import {
  adminPath,
  landingPath,
  privacyPath,
  signInPath,
  signOutPath,
  settingsPath,
  termsPath,
} from "~/common/paths"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"
import { UpdateProfileBanner } from "~/ui/UpdateProfileBanner"

export const Layout = () => {
  const {
    currentUser,
    result: { loading: currentUserLoading },
  } = useCurrentUserMaybe()

  return (
    <>
      <header>
        {currentUser?.hasPaidAccess && <UpdateProfileBanner />}
        <nav className="mx-auto p-4 pb-0 lg:px-8" aria-label="Global">
          <div className="flex flex-wrap items-center justify-between border-b pb-4 gap-4 dark:border-gray-600">
            <div className="flex-1">
              <Link
                to={landingPath.pattern}
                className="-m-1.5 p-1.5 font-bold text-2xl text-pink-600 no-underline hover:underline"
              >
                Call List
              </Link>
            </div>
            {!currentUser && !currentUserLoading && (
              <div>
                <Link to={signInPath.pattern}>Sign in</Link>
              </div>
            )}
            {currentUser && (
              <>
                {currentUser.isAdmin && (
                  <div>
                    <a href={adminPath.pattern}>Admin</a>
                  </div>
                )}
                <div>
                  <a href={settingsPath.pattern}>Settings</a>
                </div>
                <div>
                  <a href={signOutPath.pattern}>Sign out</a>
                </div>
              </>
            )}
          </div>
        </nav>
      </header>

      <div className="w-100 px-4 lg:px-8 py-8">
        {!currentUserLoading && <Outlet />}
      </div>

      <footer className="mt-auto">
        <nav className="p-4 lg:px-8">
          <div className="pt-4 flex flex-wrap items-center border-t dark:border-gray-600 gap-4 text-sm">
            <Link to="https://docs.calllist.app">Learn more</Link>
            <Link to={termsPath.pattern}>Terms</Link>
            <Link to={privacyPath.pattern}>Privacy</Link>
          </div>
        </nav>
      </footer>
    </>
  )
}
