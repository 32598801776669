import { Formik, Form } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { Contact } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { TextField } from "../ui/formFields/TextField"

interface ContactFormProps {
  contact: Contact
}

type ContactValues = {
  name: string
  email: string
  phone: string
  notes: string
}

export const ContactForm = ({ contact }: ContactFormProps) => {
  const [updateContact] = useSafeMutation(UPDATE_CONTACT_MUTATION)

  const onSubmit = async (values: ContactValues) => {
    const { data, errors } = await updateContact({
      variables: {
        input: {
          contactId: contact.id,
          ...values,
        },
      },
    })

    if (data?.updateContact.contact.id) {
      toast.dismiss()
      toast.success("Contact updated")
    } else {
      toast.error("Error updating contact")
      console.log(errors)
    }
  }

  const initialValues: ContactValues = {
    name: contact.name,
    email: contact.currentEmail ?? "",
    phone: contact.currentPhone ?? "",
    notes: contact.notes ?? "",
  }

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    email: Yup.string().email(),
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <Form className="flex-1">
          <TextField name="name" className="w-full" onBlur={handleSubmit} />
          <TextField
            name="email"
            className="w-full"
            labelClassName="mt-4"
            onBlur={handleSubmit}
          />
          <TextField
            name="phone"
            className="w-full"
            labelClassName="mt-4"
            onBlur={handleSubmit}
          />
          <TextField
            name="notes"
            as="textarea"
            className="w-full"
            labelClassName="mt-4"
            minRows="6"
            onBlur={handleSubmit}
          />
        </Form>
      )}
    </Formik>
  )
}

const UPDATE_CONTACT_MUTATION = gql(`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      contact {
        id
        availability
        contactCallNotes
        contactUrgency
        currentEmail
        currentPhone
        name
        notes
        state
        timezone
      }
    }
  }
`)
