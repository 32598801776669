import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import * as paths from "~/common/paths"
import { ErrorBoundary } from "./ui/ErrorBoundary"
import { LandingScreen } from "./screens/LandingScreen"
import { AboutScreen } from "./screens/AboutScreen"
import { TermsScreen } from "./screens/TermsScreen"
import { PrivacyScreen } from "./screens/PrivacyScreen"
import { ActiveContactsScreen } from "./screens/ActiveContactsScreen"
import { PendingContactsScreen } from "./screens/PendingContactsScreen"
import { ArchivedContactsScreen } from "./screens/ArchivedContactsScreen"
import { SignInScreen } from "./screens/SignInScreen"
import { SignUpScreen } from "./screens/SignUpScreen"
import { Layout } from "./layouts/Layout"
import { ContactsLayout } from "./layouts/ContactsLayout"
import { SettingsLayout } from "./layouts/SettingsLayout"
import { SettingsScreen } from "./screens/SettingsScreen"
import { AccountSettingsScreen } from "./screens/AccountSettingsScreen"
import { RequireUserSignedIn } from "./layouts/RequireUserSignedIn"
import { RequireUserSignedOut } from "./layouts/RequireUserSignedOut"
import { AdminLayout } from "./layouts/AdminLayout"
import { AdminUsersScreen } from "./screens/admin/AdminUsersScreen"
import { AdminUserCreateScreen } from "./screens/admin/AdminUserCreateScreen"
import { AdminUserUpdateScreen } from "./screens/admin/AdminUserUpdateScreen"
import { AvailabilityFormScreen } from "./screens/AvailabilityFormScreen"
import { NotFoundScreen } from "./screens/NotFoundScreen"
import { RequireSubscription } from "./layouts/RequireSubscription"
import { NewSubscriptionScreen } from "./screens/NewSubscriptionScreen"
import { RequireNoSubscription } from "./layouts/RequireNoSubscription"
import { EmailUnsubscribeScreen } from "./screens/EmailUnsubscribeScreen"
import { UploadContactsScreen } from "./screens/UploadContactsScreen"

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />}>
      <Route element={<RequireUserSignedIn />}>
        <Route element={<AdminLayout />} path="/admin">
          <Route
            path={paths.adminUsersIndexPath.pattern}
            element={<AdminUsersScreen />}
          />
          <Route
            path={paths.adminUserCreatePath.pattern}
            element={<AdminUserCreateScreen />}
          />
          <Route
            path={paths.adminUserUpdatePath.pattern}
            element={<AdminUserUpdateScreen />}
          />
          <Route
            index
            element={
              <Navigate replace to={paths.adminUsersIndexPath.pattern} />
            }
          />
        </Route>
      </Route>

      <Route element={<Layout />}>
        <Route path={paths.landingPath.pattern} element={<LandingScreen />} />
        <Route path={paths.aboutPath.pattern} element={<AboutScreen />} />
        <Route path={paths.termsPath.pattern} element={<TermsScreen />} />
        <Route path={paths.privacyPath.pattern} element={<PrivacyScreen />} />
        <Route
          path={paths.emailUnsubscribePath.pattern}
          element={<EmailUnsubscribeScreen />}
        />

        <Route element={<RequireUserSignedIn />}>
          <Route element={<RequireSubscription />}>
            <Route
              path={paths.dashboardPath.pattern}
              element={<ContactsLayout />}
            >
              <Route
                path={paths.dashboardPath.pattern}
                element={<ActiveContactsScreen />}
              />
              <Route
                path={paths.pendingPath.pattern}
                element={<PendingContactsScreen />}
              />
              <Route
                path={paths.archivedPath.pattern}
                element={<ArchivedContactsScreen />}
              />
            </Route>
          </Route>

          <Route element={<RequireNoSubscription />}>
            <Route
              path={paths.subscribePath.pattern}
              element={<NewSubscriptionScreen />}
            />
          </Route>
          <Route path={paths.settingsPath.pattern} element={<SettingsLayout />}>
            <Route
              path={paths.settingsPath.pattern}
              element={<SettingsScreen />}
            />
            <Route
              path={paths.accountSettingsPath.pattern}
              element={<AccountSettingsScreen />}
            />
            <Route
              path={paths.uploadContactsPath.pattern}
              element={<UploadContactsScreen />}
            />
          </Route>
        </Route>

        <Route element={<RequireUserSignedOut />}>
          <Route path={paths.signInPath.pattern} element={<SignInScreen />} />
          <Route path={paths.signUpPath.pattern} element={<SignUpScreen />} />
        </Route>

        <Route path="/not-found" element={<NotFoundScreen />} />

        <Route path="*" element={<AvailabilityFormScreen />} />
      </Route>
    </Route>
  )
)
