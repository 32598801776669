import { useQuery } from "@apollo/client"
import { Outlet, NavLink } from "react-router-dom"
import { gql } from "~/__generated__"
import { dashboardPath, pendingPath, archivedPath } from "~/common/paths"
import { UserTools } from "~/ui/UserTools"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"
import { tabLinkClassNames } from "~/util/tabLinkClassNames"

export const ContactsLayout = () => {
  const { data } = useQuery(CONTACT_LAYOUT_COUNTS_QUERY)

  const {
    currentUser,
    result: { loading: currentUserLoading },
  } = useCurrentUserMaybe()

  if (!currentUser || currentUserLoading) {
    return null
  }

  const pendingContactsCount = data?.pendingContacts.totalCount ?? 0

  return (
    <div className="max-w-7xl m-auto">
      <div className="mb-4 sticky top-0">
        <UserTools />
      </div>
      <nav className="mb-8 overflow-hidden overflow-x-auto">
        <div className="border-b dark:border-gray-600 flex">
          <NavLink to={dashboardPath.pattern} end className={tabLinkClassNames}>
            Active
          </NavLink>
          <NavLink to={pendingPath.pattern} className={tabLinkClassNames}>
            Requests {pendingContactsCount > 0 && `(${pendingContactsCount})`}
          </NavLink>
          <NavLink to={archivedPath.pattern} className={tabLinkClassNames}>
            Search Contacts
          </NavLink>
        </div>
      </nav>
      <Outlet />
    </div>
  )
}

export const CONTACT_LAYOUT_COUNTS_QUERY = gql(`
  query ContactLayoutCounts {
    pendingContacts {
      totalCount
    }
  }
`)
