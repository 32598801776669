import clsx from "clsx"
import { getIn, useField, useFormikContext } from "formik"
import Select, {
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  PropsValue,
  Props as SelectProps,
  SingleValue,
} from "react-select"
import invariant from "tiny-invariant"

/**
 * @param {{name, label, options, ...}} props
 * options => [{label: "label 1", value: 1}, {label: "label 2", value: 2}, ...]
 * where label is displayed, and value is an optional value attached for the item.
 *
 * for making group labels:
 * options => [
 *  {
 *    label: "Group A Label", <= this is optional, no group label if left as ""
 *    options: [{
 *      label: "Group A label 1",
 *      value: "Group A value 1",
 *    }]
 *  },
 *  ... Group B
 * ]
 */

type DropdownFieldOption = {
  label: string
  value: string
}

type DropdownFieldProps = {
  name: String
  label?: String
  labelClassName?: string
  containerClassName?: string
} & SelectProps<DropdownFieldOption>

const associatedValue = (
  value: string,
  options:
    | OptionsOrGroups<DropdownFieldOption, GroupBase<DropdownFieldOption>>
    | undefined
): PropsValue<DropdownFieldOption> => {
  let selectedOption = (options ?? []).find((option) => {
    if ("value" in option) {
      return option.value === value
    }
    return false
  })
  if (!selectedOption) return null
  invariant("value" in selectedOption)
  return selectedOption
}

const DropdownField = (props: DropdownFieldProps) => {
  const { label, name } = props
  const [field, meta, helpers] = useField(name)
  const { errors } = useFormikContext()
  const apiError = getIn(errors, name)
  const formatGroupLabel = (data: GroupBase<unknown>) =>
    data.label && (
      <div>
        <span>{data.label}</span>
        <hr></hr>
      </div>
    )

  const handleChange = (
    option: MultiValue<DropdownFieldOption> | SingleValue<DropdownFieldOption>
  ) => {
    if (Array.isArray(option)) {
      console.error("multiple values not handled yet")
    } else {
      helpers.setValue((option as SingleValue<DropdownFieldOption>)?.value)
    }
  }

  return (
    <div className={clsx(props.containerClassName)}>
      {label && (
        <label
          className={clsx("font-bold block", props.labelClassName)}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <Select
        {...field}
        // display null for value if value is empty
        value={associatedValue(field.value, props.options)}
        id={name}
        inputId={name}
        className={meta.touched && meta.error ? " is-invalid" : ""}
        classNamePrefix="react-select"
        formatGroupLabel={formatGroupLabel}
        onChange={handleChange}
        {...props}
        unstyled
        onBlur={() => helpers.setTouched(true)}
        classNames={{
          control: () =>
            "w-full border-2 border-black dark:border-white dark:bg-black p-3",
          menu: () =>
            "bg-white dark:bg-black border-2 border-black dark:border-white border-t-0",
          option: () => "px-3 py-2",
        }}
      />
      {meta.touched && (meta.error || apiError) && (
        <div className="invalid-feedback">{meta.error || apiError}</div>
      )}
    </div>
  )
}

export default DropdownField
